import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry, throwError } from 'rxjs';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { AppConfigService } from 'src/sites/core/services/appconfig.service';
import { encryptedValue } from '../authentication/encryptedValue';
import { StorageService } from '../authentication/storage.service';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { jsonIgnoreReplacer } from 'json-ignore';

@Injectable()
export class AppointmentService {

   content : string = "";

   constructor(
      private appConfigService : AppConfigService,
      private httpClient: HttpClient,
      public accessRightsService: AccessRightsService,
      public storageService: StorageService,
      private encryptionService: EncryptionService) {
   }

   public sendMatchAppointment(teams: number[], email: string) {
      //console.log("TRACE -> sendMatchAppointment");

      let encryptedData = new encryptedValue();
      encryptedData.value = this.encryptionService.encryptObject(true, false, teams);

      if (this.accessRightsService.isUserConnected) { 
         let url = "/Q100200001?email=" + email;
         return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/C1002' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
            .pipe(retry(1), catchError(this.handleError));
      }
   }k

   public sendComiteeMeetingAppointment(comiteeMeetingSysId: number, emailTo: string, emailCc: string, isCancelled?: boolean) {
      //console.log("TRACE -> sendComiteeMeetingAppointment");

      let encryptedData = new encryptedValue();
      encryptedData.value = this.encryptionService.encryptObject(true, false, comiteeMeetingSysId);

      if (this.accessRightsService.isUserConnected) {
         let url = "/Q100200002?emailTo=" + emailTo + "&emailCc=" + emailCc + "&isCancelled=" + isCancelled;
         return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/C1002' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
            .pipe(retry(1), catchError(this.handleError));
      }
   }
   
   // Error handling 
   handleError(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
      } else {
         // Get server-side error
               if (error.status == 401)
        errorMessage = "Vous n'êtes pas autorisé à effectuer cette opération.";
      else
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(() => errorMessage);
   }
}
