import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Md5 } from 'ts-md5';
import { strings } from '../../models/strings';
import { CacheService } from '../../services/cache.service';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { enumCacheStatusLoad } from 'src/sites/core/enum/Enum';

@Component({
   selector: 'app-adminDevelopment',
   templateUrl: './adminDevelopment.component.html',
   styleUrls: ['./adminDevelopment.component.scss']
})
export class AdminDevelopmentComponent implements OnInit {
   public allTodos: strings[] = [];
   public titleDescriptionDisplay: string = "";

   constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private translateService: TranslateService,
   ) {
   }

   public getPasswordMD5() {
      const md5 = new Md5();
      md5.appendStr("B0urlett1s#!#VAnderlu3s#.be@Jdax_BELGIQUE")
      return md5.end().toString();
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.allTodos = this.cacheService.getAllTodos().map((x) => {
         return x;
      });

      this.refresh();
   }


   public isSeparator(todo: strings) {
      return todo.value.startsWith("#");
   }

   public isSeparatorLi(todo: strings) {
      return todo.value.startsWith("*");
   }

   public ngOnInit() {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });
     }

   public onFilterChange(): void {
      this.refresh();
   }

   public refresh() {
   }

   public translatePage() {
   }
}
