//This file is autogenerated by C# GeneratedServices/Angular/ServicesAngularGenerationTool

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry, throwError } from 'rxjs';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { LogService } from 'src/sites/core/services/log.service';
import { AppConfigService } from './appconfig.service';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { mailRequest } from '../models/mail/mailRequest';

@Injectable()
export class MailService {
   public username = "UNKNOWN";

   constructor(
      private appConfigService: AppConfigService,
      private logger: LogService,
      private httpClient: HttpClient,
      private frameworkService: FrameworkService,
      private accessRightsService: AccessRightsService,
      private storageService: StorageService
   ) {
      this.httpClient = httpClient;
      this.accessRightsService = accessRightsService;
      this.username = !this.accessRightsService.isUserConnected ? "UNKNOWN" : this.storageService.getUserConnected()?.username;
   }

   public sendMail(request: mailRequest) {
      let url = "/Q101500001";
      return this.httpClient.post<boolean>(this.appConfigService.getApiUrl() + 'api/C1015' + url, request)
         .pipe(retry(0), catchError(this.handleError));
   }

   // Error handling
   public handleError(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
      } else {
         // Get server-side error
               if (error.status == 401)
        errorMessage = "Vous n'êtes pas autorisé à effectuer cette opération.";
      else
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(() => errorMessage);
   }
}