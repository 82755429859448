<form>
   <mat-card>
   <mat-card-header>
      <mat-card-title>
         {{importDataTitleDisplay}} {{importChampionshipDisplay}}
      </mat-card-title>
   </mat-card-header>

   <mat-card>
      <mat-card-content>
         <div class="matcard matcardFloat">
            <app-panel [widthPanel]="'600px'">
               <span panel-title>Publication des membres affiliés</span>
               <div panel-content>
                  <p>(A partir du fichier d'export Excel de Clubee)</p>
                  <input style="display:none" id="input-file-id1" multiple type="file"
                     (change)="onFilesSelected1($event)" />

                  <span class="buttons">
                     <label class="nav-link navbarLink buttonize" for="input-file-id1">
                        Choisir ...
                     </label>
                     <span class="centeredSpan">
                     &nbsp;{{selectedFiles1.length}} fichier(s) sélectionné(s)&nbsp;
                     </span>
                     <app-button icon="upload_file" [isFlicked]="isSynchronisationRunning"
                     [disabled]="selectedFiles1.length == 0 || !isNetworkStatusOnline" 
                     [text]="'Upload'" [isBackgroundOpaque]="true" 
                     (onClick)="uploadFiles1()">
                     </app-button>

                     <app-buttonmenu [disabled]="!isNetworkStatusOnline" [isFlicked]="isSynchronisationRunning"
                        [isBackgroundOpaque]="true" icon="change_circle" routerLink="/adminimportdata"
                        (click)="fvwbSynchronize(2)"></app-buttonmenu>
                  </span>
               </div>
            </app-panel>
         </div>
         <div class="matcard matcardFloat">
            <app-panel [widthPanel]="'600px'">
               <span panel-title>Publication des membres non affiliés</span>
               <div panel-content>
                  <p>(A partir du fichier Excel manuel basé sur le modèle de Clubee)</p>
                  <input style="display:none" id="input-file-id2" multiple type="file"
                     (change)="onFilesSelected2($event)" />

                  <span class="buttons">
                     <label class="nav-link navbarLink buttonize" for="input-file-id2">
                        Choisir ...
                     </label>
                     <span class="centeredSpan">
                     &nbsp;{{selectedFiles2.length}} fichier(s) sélectionné(s)&nbsp;
                     </span>
                     <app-button icon="upload_file" [isFlicked]="isSynchronisationRunning"
                     [disabled]="selectedFiles2.length == 0 || !isNetworkStatusOnline" 
                     [text]="'Upload'" [isBackgroundOpaque]="true" 
                     (onClick)="uploadFiles2()">
                     </app-button>

                     <app-buttonmenu [disabled]="!isNetworkStatusOnline" [isFlicked]="isSynchronisationRunning"
                        [isBackgroundOpaque]="true" icon="change_circle" routerLink="/adminimportdata"
                        (click)="fvwbSynchronize(2)"></app-buttonmenu>
                  </span>
               </div>
            </app-panel>
         </div>
         <div class="matcard matcardFloat">
            <app-panel [widthPanel]="'600px'">
               <span panel-title>Synchronisation des rencontres et classements seniors</span>
               <div panel-content>
                  <span class="buttons">
                     <app-buttonmenu [disabled]="!isNetworkStatusOnline" [isFlicked]="isSynchronisationRunning"
                        [isBackgroundOpaque]="true" icon="change_circle" routerLink="/adminimportdata"
                        (click)="fvwbSynchronize(3)"></app-buttonmenu>
                  </span>
               </div>
            </app-panel>
         </div>
         <div class="matcard matcardFloat">
            <app-panel [widthPanel]="'600px'">
               <span panel-title>Publication des rencontres des U17</span>
               <div panel-content>
                  <p>(A partir du fichier Excel manuel)</p>

                  <app-button icon="file_download" [text]="'Template Excel'" [isBackgroundOpaque]="true"
                     (onClick)="exportToExcel()"></app-button>
                  <div class="br1"></div>
                  <input style="display:none" id="input-file-id3" multiple type="file"
                     (change)="onFilesSelected3($event)" />

                  <span class="buttons">
                     <label class="nav-link navbarLink buttonize" for="input-file-id3">
                        Choisir ...
                     </label>
                     <span class="centeredSpan">
                     &nbsp;{{selectedFiles3.length}} fichier(s) sélectionné(s)&nbsp;
                     </span>
                     <app-button icon="upload_file" 
                     [disabled]="selectedFiles3.length == 0 || !isNetworkStatusOnline" 
                     [text]="'Upload'" [isBackgroundOpaque]="true" 
                     (onClick)="uploadFiles3()">
                     </app-button>

                     <app-buttonmenu [disabled]="!isNetworkStatusOnline" [isFlicked]="isSynchronisationRunning"
                        [isBackgroundOpaque]="true" icon="change_circle" routerLink="/adminimportdata"
                        (click)="fvwbSynchronize(4)"></app-buttonmenu>
                  </span>
               </div>
            </app-panel>
         </div>
      </mat-card-content>
   </mat-card>
   <mat-card>
      <mat-card-header>
         <mat-card-title>
            A partir de clubee
         </mat-card-title>
      </mat-card-header>
      <mat-card-content>
         <div class="matcard matcardFloat">
            <app-panel>
               <span panel-title>Connexion à clubee</span>
               <div panel-content>
                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'"
                     [text]="'Dashboard Clubee'" [type]="EnumHRefType.HttpNewWindow"
                     [href]="'https://mobile.clubee.com/app?lang=fr_fr&c=profile&profile=dashboard'" alt="">
                  </app-buttonhref>
               </div>
            </app-panel>
         </div>
      </mat-card-content>
   </mat-card>
   <mat-card>
      <mat-card-header>
         <mat-card-title>
            A partir d'Achvb
         </mat-card-title>
      </mat-card-header>
      <mat-card-content>
         <div class="matcard matcardFloat">
            <app-panel>
               <span panel-title>Les jeunes</span>
               <div panel-content>
                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'"
                     [text]="exportFilesFromAchvbPortal" [type]="EnumHRefType.HttpNewWindow"
                     [href]="'https://www.achvb.be/'" alt="">
                  </app-buttonhref>
                  <!-- <app-button icon="input" [class]="'spanButtonSeparator'" [text]="'ImportYoung' | translate"
                     [isBackgroundOpaque]="true" (onClick)="fileInputYoung.click()"></app-button>
                  <input hidden (change)="onFileChange($event, true)" #fileInputYoung type="file" multiple="false"> -->
               </div>
            </app-panel>
         </div>

      </mat-card-content>
   </mat-card>



   <div class="alertShow">
      <app-alertShow></app-alertShow>
   </div>
</mat-card>
</form>