import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, from, fromEvent, map, merge, mergeMap, Observable, ObservableInput, of, Subscription, tap } from 'rxjs';

import { user } from 'src/sites/core/models/user/user';
import { workgroup } from 'src/sites/core/models/workgroup/workgroup';
import { championship } from '../models/championship';
import { comiteeMeeting } from '../models/comiteeMeeting';
import { match } from '../models/match';
import { team } from '../models/team';
import { trainingPlayer } from '../models/trainingPlayer';

// import * as signalR from '@microsoft/signalr';
import 'linq-typed';
import { enumActivityType, enumAvailability, enumAvailabilityReason, enumCacheStatusLoad, enumCacheTypeLoad, enumClubActivityType, enumIndexDbA, enumIndexDbB, enumQueryFilterOp } from 'src/sites/core/enum/Enum';
import { lastUpdates } from 'src/sites/core/models/lastupdates/lastUpdates';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { LastUpdatesService } from 'src/sites/core/services/lastUpdates.service';
import { UserService } from 'src/sites/core/services/user.service';
import { WorkgroupService } from 'src/sites/core/services/workgroup.service';
import { AuthenticationService } from 'src/sites/vabourlettis/authentication/authentication.service';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { activity } from '../models/activity';
import { buildingTrainingClosingDay } from '../models/buildingTrainingClosingDay';
import { eventClub } from '../models/eventClub';
import { homeNews } from '../models/homeNews';
import { queryFilter } from '../models/queryFilter';
import { ranking } from '../models/ranking';
import { sponsor } from '../models/sponsor';
import { sportsClub } from '../models/sportsClub';
import { strings } from '../models/strings';
import { ActivityService } from './activity.service';
import { db } from './appDB';
import { BuildingTrainingClosingDayService } from './buildingTrainingClosingDay.service';
import { ChampionshipService } from './championship.service';
import { ComiteeMeetingService } from './comiteeMeeting.service';
import { HomeNewsService } from './homeNews.service';
import { MatchService } from './match.service';
import { RankingService } from './ranking.service';
import { SponsorService } from './sponsor.service';
import { SportsClubService } from './sportsClub.service';
import { TeamService } from './team.service';
import { TodoService } from './todoService';
import { TrainingPlayerService } from './trainingPlayer.service';
import { VersionningService } from './versionningService';
import { entityUpdatedRequest } from '../models/entityUpdateRequest';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { SignalRService } from 'src/sites/core/services/signalR.service';
import { DataPrivacyDialogComponent, DataPrivacyDialogModel } from 'src/app/common/footer/dataPrivacyDialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { handleError } from 'src/app/common/tool/error';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/sites/core/services/appconfig.service';

@Injectable({
   providedIn: 'root'
})
export class CacheService implements OnInit, OnDestroy {
   private _networkStatus$: Subscription = Subscription.EMPTY;

   private _currentChampionship: championship;
   private _previousChampionship: championship;
   private _allActivities: activity[] = [];
   private _allBuildingTrainingClosingDay: buildingTrainingClosingDay[] = [];
   private _allChampionships: championship[] = [];
   private _allClubEvents = new Map<string, eventClub>();
   private _allClubEventsOfUserAuthenticated = new Map<string, eventClub>();
   private _allCoachsOfCurrentLogged: user[] = [];
   private _allComiteeMeetings: comiteeMeeting[] = [];
   private _allEntitiesToUpdate: lastUpdates[] = [];
   private _allHomeNews: homeNews[] = [];
   private _allMatchs: match[] = [];
   private _allMatchsOfUserTeamAndClub: match[] = [];
   private _allRankings: ranking[] = [];
   private _allSponsors: sponsor[] = [];
   private _allTeams: team[] = [];
   private _allTeamsWithoutPicture: team[] = [];
   private _allTodos: strings[] = [];
   private _allTrainingDays: any[] = [];
   private _allTrainingPlayers: trainingPlayer[] = [];
   private _allTrainingPlayersOfCurrentLogged: trainingPlayer[] = [];
   private _allTrainingPlayersOfUserCoachConnected: trainingPlayer[] = [];
   private _allUsers: user[] = [];
   private _allUsersOfTeamOfCurrentUser: user[] = [];
   private _allMembersForMainTeam: user[] = [];
   private _allMembersForSecondaryTeam: user[] = [];
   private _allMembersByFirstname: user[] = [];
   private _allMembersByLastname: user[] = [];
   private _allMembersByRole;
   private _allMembersByTeam: user[] = [];
   private _allVersions: strings[] = [];
   private _allWorkgroups: workgroup[] = [];
   private _coachOfCurrentLogged: user = null;
   private _sportsClub: sportsClub = null;
   private _teamOfCurrentUser: team = null;
   private _queryFiltersOnChampionship = [];
   private _isFirstTimeNetworkCheck: boolean = true;
   public _isNetworkStatusOnline: boolean = false;

   constructor(
      private frameworkService: FrameworkService,
      public accessRightsService: AccessRightsService,
      private lastUpdatesService: LastUpdatesService,
      private userService: UserService,
      private championshipService: ChampionshipService,
      private buildingTrainingClosingDayService: BuildingTrainingClosingDayService,
      private homeNewsService: HomeNewsService,
      private teamService: TeamService,
      private matchService: MatchService,
      private trainingPlayerService: TrainingPlayerService,
      private comiteeMeetingService: ComiteeMeetingService,
      private workgroupService: WorkgroupService,
      private activityService: ActivityService,
      private sponsorService: SponsorService,
      private versionningService: VersionningService,
      private todoService: TodoService,
      private sportsClubService: SportsClubService,
      private rankingService: RankingService,
      private authenticationService: AuthenticationService,
      public storageService: StorageService,
      private encryptionService: EncryptionService,
      private signalRService: SignalRService,
      private subjectService: SubjectService,
      private router: Router,
      public dialog: MatDialog
   ) {
      //POPUP PRIVACY (subscribe AFTER close)
      this.subjectService.IsPrivacyPopupClosedSubject.subscribe(value => { if (value != null) this.cacheIntialize(true, false); });
      this.subjectService.GlobalCacheLoadedStatusSubject.next(enumCacheStatusLoad.none);

      //Check update each 30s if web api is online
      //ICI (COMMENT IF TEST PLAN)
       setInterval(() => {
          if (!this.subjectService.IsGlobalCacheInLoading && navigator.onLine) {
             this.cacheUpdatedDataLoad(false);
          }
       }, 60000);

      //Check internet status
      //CACHE CHECK navigator status
      this.frameworkService.traceCache("Constructor", "CHECK navigator status");
      merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
         .pipe(map(() => navigator.onLine))
         .subscribe(status => { this.subjectService.IsNetworkStatusOnlineSubject.next(status); });

      //Start SignalR connection
      //CACHE START SignalR

      this.frameworkService.traceCache("Constructor", "START SignalR");
      this.signalRService.startConnection(this.subjectService);

      //Listen if internet status changed
      //If change, check if privacy is already accepted. If not yet, ask from popup
      //CACHE CHECK IsNetworkStatusOnlineSubject change
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => {
         this._isNetworkStatusOnline = value;
         if (this.storageService.isDataPrivacyIsNull()) {
            //POPUP PRIVACY
            this.showPrivacyPopup();
         }
         else
            this.cacheIntialize(false, false);
      });

      this._cachePartLoadVersion();
   }

   public ngOnInit() {

   }

   public ngOnDestroy(): void {
      this._networkStatus$.unsubscribe();
   }

   ////////////////// > RESET
   public async resetData() {
      //Remove collection not used in cache on public mode
      db.delete().then(() => { }).catch((err) => { }).finally(() => {
      });
      this.resetUnloggedData();
      this.storageService.setDataPrivacyCookie(false);
   }

   public resetUnloggedData() {
      this._allComiteeMeetings = [];
      this._allTrainingPlayers = [];
      this._allTrainingPlayersOfCurrentLogged = [];
      this._allTrainingPlayersOfUserCoachConnected = [];
   }
   ////////////////// < RESET

   ////////////////// > LOGIN
   public storeTokenAndRefreshCache(user, encryptedValue) {
      if (user.accountLoginTries == 0) {
         //-> Store the token
         this.storageService.saveToken(encryptedValue);
         //-> Set Userconnected subject
         this.subjectService.UserConnectedSubject.next(user);
         //-> REFRESH the cacheService
         this.cacheStartLoad(enumCacheTypeLoad.fromWebAPI, false, false);
         this.router.navigate(['/home']);
      }
   }
   ////////////////// < LOGIN

   ////////////////// > CACHE FULL RELOAD
   //CACHE FULL RELOAD
   public cacheFullReload(isWebApiShouldBeCalled: boolean) {
      this.frameworkService.traceCache("cacheFullReload", "isWebApiShouldBeCalled", isWebApiShouldBeCalled);
      this.subjectService.GlobalCacheLoadedStatusSubject.next(enumCacheStatusLoad.none);
      this._isFirstTimeNetworkCheck = true;
      this.cacheIntialize(isWebApiShouldBeCalled, true);
   }
   ////////////////// < CACHE FULL RELOAD

   private async _cachePartLoadVersion() {
      if (navigator.onLine) {
         let requestCount = 0;
         let forkJoinSource: Record<string, ObservableInput<any>> = {};
         await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbA[enumIndexDbA.versionning], null);

         this.frameworkService.traceCache("_cachePartLoadVersion", "forkJoinSource", forkJoinSource);

         from(Object.entries(forkJoinSource)).pipe(
            mergeMap(([key, obs]) =>
               (obs as Observable<any>).pipe(
                  tap((response) => {
                     this.frameworkService.traceCache("_cachePartLoadVersion", "query executed", key);
                     this._cacheGetFromForkJoinSource(key, response);
                  })
               )
            )
         ).subscribe(
            {
               next: (v) => {
                  requestCount++;
                  this.frameworkService.traceCache("_cachePartLoadVersion", "requestCount", requestCount);
                  if (requestCount === Object.keys(forkJoinSource).length) {
                     this.frameworkService.traceCache("_cachePartLoadVersion", "(WEBAPI = 2)");
                     this.subjectService.VersionNumberSubject.next(this._allVersions[0]?.value.replace("SITE ", ""));
                  }
               },
               error: async (e) => {
                  this.frameworkService.traceCache("_cachePartLoadVersion", "error requestCount", requestCount);
                  const versionningDB = await db.versionningDB.toArray();
                  this._allVersions = versionningDB;
                  this.subjectService.VersionNumberSubject.next(this._allVersions[0]?.value.replace("SITE ", ""));
               }
            }
         );
      }
      else {
         this.frameworkService.traceCache("_cachePartLoadVersion", "(INDEXDB = 1)");
         const versionningDB = await db.versionningDB.toArray();
         this._allVersions = versionningDB;
         this.subjectService.VersionNumberSubject.next(this._allVersions[0]?.value.replace("SITE ", ""));
      }
   }

   ////////////////// > CACHE INIT
   public cacheIntialize(isWebApiShouldBeCalled: boolean, isFullReload: boolean) {
      this.frameworkService.traceCache("cacheIntialize", "isWebApiShouldBeCalled", isWebApiShouldBeCalled);
      this.frameworkService.traceCache("cacheIntialize", "_isFirstTimeNetworkCheck 1", this._isFirstTimeNetworkCheck);
      if (this._isNetworkStatusOnline) {
         if (this._isFirstTimeNetworkCheck) {
            //> ONLINE PROCESS
            this.frameworkService.traceCache("cacheIntialize", "ONLINE / IsNetworkStatusOnlineSubject CHANGE");
            //>>> Initialisation ONLINE 
            //-> Load data from web api
            //-> Check if site is cached
            if (!this.storageService.isDataPrivacyIsNull() && this.storageService.isDataPrivacyAccepted() && !isWebApiShouldBeCalled) {
               this.frameworkService.traceCache("cacheIntialize", "ONLINE / cacheStartLoad (INDEXDB = 1)");
               //         -> 11. Yes site is cached, load data from indexDB
               this.cacheStartLoad(enumCacheTypeLoad.fromIndexDB, isWebApiShouldBeCalled, isFullReload);
            }
            else {
               this.frameworkService.traceCache("cacheIntialize", "ONLINE / cacheStartLoad (WEBAPÏ = 2)");
               //         -> 12. No, site is NOT cached, load data from WEB API
               this.cacheStartLoad(enumCacheTypeLoad.fromWebAPI, isWebApiShouldBeCalled, isFullReload);
            }
         }
         else {
            this.frameworkService.traceCache("cacheIntialize", "ONLINE / UPDATE");
            //>>> A timer look for the update. No need to do it here
         }
      }
      else {  //> OFFLINE PROCESS
         if (this._isFirstTimeNetworkCheck) {
            this.frameworkService.traceCache("cacheIntialize", "OFFLINE / IsNetworkStatusOnlineSubject CHANGE");
            //>>> Initialisation OFFLINE
            //-> Check if site is cached
            if (!this.storageService.isDataPrivacyIsNull() && this.storageService.isDataPrivacyAccepted()) {
               this.frameworkService.traceCache("cacheIntialize", "OFFLINE / cacheStartLoad (INDEXDB = 1)");
               //         -> 11. Yes site is cached, load data from INDEX DB
               //             - check if userConnected
               this.cacheStartLoad(enumCacheTypeLoad.fromIndexDB, isWebApiShouldBeCalled, false);
            }
            else {
               this.frameworkService.traceCache("cacheIntialize", "OFFLINE / No load");
               //         -> 12. No site is cached, NO LOAD AT ALL (WAIT update network status change)
            }

         }
         else {
            this.frameworkService.traceCache("cacheIntialize", "OFFLINE / UPDATE");
            //>>> A timer look for the update. No need to do it here
         }

      }
      this._isFirstTimeNetworkCheck = false;
      this.frameworkService.traceCache("cacheIntialize", "_isFirstTimeNetworkCheck 2", this._isFirstTimeNetworkCheck);
   }
   ////////////////// < CACHE INIT

   ////////////////// > CACHE UPDATE
   public async cacheUpdatedDataLoad(isWebApiShouldBeCalled: boolean) {
      try {
         this.frameworkService.traceCache("cacheUpdatedDataLoad", "CHECK IF DATA SHOULD BE UPDATED");

         let forkJoinSource: Record<string, ObservableInput<any>> = {};
         forkJoinSource = {};

         var request = new entityUpdatedRequest();

         request.lastClientUpdate = new Date(1970, 1, 1);
         request.lastClientReset = new Date(1970, 1, 1);

         if (this.storageService.getLastUpdateCookie() != null)
            request.lastClientUpdate = this.frameworkService.getDateTimeFromDateTimeString(this.storageService.getLastUpdateCookie());

         if (this.storageService.getLastResetCookie() != null)
            request.lastClientReset = this.frameworkService.getDateTimeFromDateTimeString(this.storageService.getLastResetCookie());

         forkJoinSource[enumIndexDbB[enumIndexDbB.lastupdates]] = this.lastUpdatesService.getEntityUpdated(request);

         forkJoin(forkJoinSource).subscribe({
            next: data => {
               //Retrieve _all entities to update
               this._allEntitiesToUpdate = this.encryptionService.decryptObject(false, data[enumIndexDbB[enumIndexDbB.lastupdates]].value);
               if (this._allEntitiesToUpdate != null) {
                  if (this._allEntitiesToUpdate.length > 0) {
                     this.frameworkService.logInfo(this._allEntitiesToUpdate.map(i => i.entityName.toLowerCase()), "cacheUpdatedDataLoad DATA TO UPDATED");
                     this.frameworkService.traceCache("cacheUpdatedDataLoad", "DATA TO UPDATED", this._allEntitiesToUpdate.map(i => i.entityName.toLowerCase()));
                     //CACHE RESET
                     if (this._allEntitiesToUpdate.map(i => i.entityName.toLowerCase()).Contains("reset")) {
                        this.frameworkService.logInfo("cacheUpdatedDataLoad", "RESET THE CACHE !");
                        this.cacheFullReload(true);
                     }
                     else {
                        this.frameworkService.logInfo("cacheUpdatedDataLoad", "UPDATE THE CACHE !");
                        this.cacheUpdateLoad(this._allEntitiesToUpdate.map(i => i.entityName.toLowerCase()), isWebApiShouldBeCalled);
                     }
                  }
                  else {
                     this.frameworkService.logInfo("cacheUpdatedDataLoad", "NO DATA TO UPDATE");
                     this.frameworkService.traceCache("cacheUpdatedDataLoad", "NO DATA TO UPDATE");
                  }
               }
               else {
                  this.frameworkService.logInfo("cacheUpdatedDataLoad", "NO DATA TO UPDATE");
                  this.frameworkService.traceCache("cacheUpdatedDataLoad", "NO DATA TO UPDATE");
               }

               this.subjectService.IsButtonInLoadingSubject.next(false);
            },
            error: err => {
            }
         });
      }
      catch (error) {
         this.frameworkService.logInfo("cacheUpdatedDataLoad", "ERROR");
         //this.frameworkService.traceCache("cacheUpdatedDataLoad", "CACHE ERROR", error);
      }
   }

   public async cacheUpdateLoad(updatesList: any, isWebApiShouldBeCalled: boolean) {

      if (updatesList != null) {
         //-> Set filter to current championship
         this._currentChampionship = this.getAllChampionships().FirstOrDefault(i => i.isCurrent);
         this._queryFiltersOnChampionship = [];
         this._queryFiltersOnChampionship.push(new queryFilter({ propertyName: "ChampionshipSysId", operation: enumQueryFilterOp.Equals, value: this._currentChampionship.sysId }));
         //<- Set filter to current championship

         let requestCount = 0;
         let forkJoinSource: Record<string, ObservableInput<any>> = {};

         updatesList.forEach(async element => {
            if (element != 'token' && element != 'versionning' && element != 'championship' && element != 'workgroup')
               await this._cacheAddToForkJoinSource(forkJoinSource, element, this._queryFiltersOnChampionship);
            else
               await this._cacheAddToForkJoinSource(forkJoinSource, element, null);
         });

         from(Object.entries(forkJoinSource)).pipe(
            mergeMap(([key, obs]) =>
               (obs as Observable<any>).pipe(
                  tap((response) => {
                     this.frameworkService.traceCache("cacheUpdateLoad", "query executed", key);
                     this._cacheGetFromForkJoinSource(key, response);
                     if (key != "token" && this.storageService.isDataPrivacyAccepted()) {
                        this.frameworkService.traceCache("cacheUpdateLoad", "_cacheSaveToIndexDB", key);
                        this._cacheSaveToIndexDB(key);
                     }
                  })
               )
            )
         ).subscribe(
            {
               next: (v) => {
                  requestCount++;
                  if (requestCount === Object.keys(forkJoinSource).length) {
                     this.frameworkService.traceCache("cacheUpdateLoad", "last query executed", requestCount);
                     this._cachePartLoadEnd(enumCacheTypeLoad.fromWebAPI, isWebApiShouldBeCalled);
                  }
               },
               error: (error) => {
                  handleError("cacheUpdateLoad", error);
                  this._cachePartLoadEndOnError();
               }
            }
         );
      }
   }
   ////////////////// < CACHE UPDATE

   ////////////////// > CACHE LOAD
   //CACHE START LOAD
   public async cacheStartLoad(cacheType: enumCacheTypeLoad, isWebApiShouldBeCalled: boolean, isFullReload: boolean) {
      try {

         /*
            cacheType == none,
            cacheType == fromIndexDB,
            cacheType == fromWebAPI AND navigator.onLine -> if isFullReload DELETE + RECREATE DB ELSE RESET DB
            cacheType == fromUpdate
         
            if (cacheType == fromIndexDB)
            -> No need to check the token, load data from indexdb
            ELSE
            -> Check the status of the token. Exist or expired ???
         */

         let requestCount = 0;
         let forkJoinSource: Record<string, ObservableInput<any>> = {};
         let isTokenRequestShouldBeCalled: boolean = false;

         if (!this.subjectService.IsGlobalCacheInLoading) {
            if (cacheType == enumCacheTypeLoad.fromIndexDB) {
               //-> No need to check the token, load data from indexdb
               isTokenRequestShouldBeCalled = false;
               this.frameworkService.traceCache("cacheStartLoad", "_cachePartLoad1", cacheType);
               this._cachePartLoad1(cacheType, isWebApiShouldBeCalled);
            }
            else {
               //TOKEN MANAGEMENT
               //Check if the token exists
               if (this.storageService.getTokenFromStorage() != null) {
                  //Check if the token is not expired
                  var isTokenExpired = ((Date.now() - new Date(this.storageService.getTokenFromStorage()?.refreshTokenExpiryTime).getTime()) > 0);
                  //Below: If Access Token Expired
                  if (isTokenExpired && this.storageService.getTokenFromStorage() != null) {
                     isTokenRequestShouldBeCalled = true;
                     await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbA[enumIndexDbA.refreshToken], null);
                  }
                  else {
                     isTokenRequestShouldBeCalled = false;
                     //Token is good, resume the process
                     this.cacheStartTokenResponse(cacheType, isWebApiShouldBeCalled, isFullReload);
                  }
               }
               else {
                  //The user is not logged !!!, DO nothing, resume the process without the token
                  isTokenRequestShouldBeCalled = false;
                  this.cacheStartTokenResponse(cacheType, isWebApiShouldBeCalled, isFullReload);
               }

            }
         }

         if (isTokenRequestShouldBeCalled) {
            from(Object.entries(forkJoinSource)).pipe(
               mergeMap(([key, obs]) =>
                  (obs as Observable<any>).pipe(
                     tap((response) => {
                        this.frameworkService.traceCache("cacheStartLoad", "query executed", cacheType + "/" + key);
                        this._cacheGetFromForkJoinSource(key, response);
                     })
                  )
               )
            ).subscribe(
               {
                  next: (v) => {
                     requestCount++;
                     this.frameworkService.traceCache("cacheStartLoad", "requestCount", requestCount);
                     if (requestCount === Object.keys(forkJoinSource).length) {
                        this.frameworkService.traceCache("cacheStartLoad", "last query executed", requestCount);
                        this.frameworkService.traceCache("cacheStartLoad", "cacheStartTokenResponse CALL", cacheType + "/" + isWebApiShouldBeCalled);
                        this.cacheStartTokenResponse(cacheType, isWebApiShouldBeCalled, isFullReload);
                     }
                  },
                  error: (e) => {
                     this.frameworkService.traceCache("cacheStartLoad", "error requestCount", requestCount);
                     handleError("cacheStartLoad", e);
                     this._cachePartLoadEndOnError();
                  }
               }
            );
         }
      }
      catch (error) {
         this._cachePartLoadEndOnError();
         handleError("cacheStartLoad", error);
      }
   }

   public cacheStartTokenResponse(cacheType: enumCacheTypeLoad, isWebApiShouldBeCalled: boolean, isFullReload: boolean) {
      try {
         //this.subjectService.IsButtonInLoadingSubject.next(true); //TODO : Don't block _all buttons. Only button which c_all CRUD operation
         this.subjectService.GlobalCacheLoadedStatusSubject.next(enumCacheStatusLoad.loading);

         if (cacheType == enumCacheTypeLoad.fromWebAPI && navigator.onLine) {
            if (this.storageService.isDataPrivacyAccepted()) {
               this.frameworkService.traceCache("cacheStartLoad", "RESET DB");

               db.close();
               if (isFullReload) {
                  //Remove _all data from the browser (reset)

                  db.delete().then(() => { }).catch((err) => { }).finally(() => {
                     //INITIALIZE DB
                     db.initializeDB();
                     db.open().then(() => { }).catch((err) => { }).finally(() => {
                        this.frameworkService.traceCache("cacheStartLoad", "_cachePartLoad1", cacheType);
                        this._cachePartLoad1(cacheType, isWebApiShouldBeCalled);
                     });
                  });
               }
               else {
                  //INITIALIZE DB
                  db.initializeDB();
                  db.open().then(() => { }).catch((err) => { }).finally(() => {
                     this.frameworkService.traceCache("cacheStartLoad", "_cachePartLoad1", cacheType);
                     this._cachePartLoad1(cacheType, isWebApiShouldBeCalled);
                  });
               }
            }
            else {
               this.frameworkService.traceCache("cacheStartLoad", "_cachePartLoad1", cacheType);
               this._cachePartLoad1(cacheType, isWebApiShouldBeCalled);
            }
         }
         else {
            this.frameworkService.traceCache("cacheStartLoad", "_cachePartLoad1", cacheType);
            this._cachePartLoad1(cacheType, isWebApiShouldBeCalled);
         }
      }
      catch (error) {
         this._cachePartLoadEndOnError();
         handleError("cacheStartLoad", error);
      }
   }

   private async _cachePartLoad1(cacheType: enumCacheTypeLoad, isWebApiShouldBeCalled: boolean) {
      if (cacheType == enumCacheTypeLoad.fromWebAPI) {
         let requestCount = 0;
         let forkJoinSource: Record<string, ObservableInput<any>> = {};
         await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbA[enumIndexDbA.none], null);
         await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbA[enumIndexDbA.championship], null);
         await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbA[enumIndexDbA.workgroup], null);

         this.frameworkService.traceCache("_cachePartLoad1", "forkJoinSource", forkJoinSource);

         from(Object.entries(forkJoinSource)).pipe(
            mergeMap(([key, obs]) =>
               (obs as Observable<any>).pipe(
                  tap((response) => {
                     this.frameworkService.traceCache("_cachePartLoad1", "query executed", cacheType + "/" + key);
                     this._cacheGetFromForkJoinSource(key, response);
                     if (key != "token" && this.storageService.isDataPrivacyAccepted()) {
                        this.frameworkService.traceCache("_cachePartLoad1", "_cacheSaveToIndexDB", key);
                        this._cacheSaveToIndexDB(key);
                     }
                  })
               )
            )
         ).subscribe(
            {
               next: (v) => {
                  requestCount++;
                  this.frameworkService.traceCache("_cachePartLoad1", "requestCount", requestCount);
                  if (requestCount === Object.keys(forkJoinSource).length) {
                     this.frameworkService.traceCache("_cachePartLoad1", "last query executed", requestCount);
                     //-> Set filter to current championship
                     this._currentChampionship = this.getAllChampionships().FirstOrDefault(i => i.isCurrent);
                     this.frameworkService.traceCache("_cachePartLoad1", "_cachePartLoad2 CALL 1", cacheType + "/" + isWebApiShouldBeCalled);
                     this.frameworkService.traceCache("_cachePartLoad1", "this.getAllChampionships().length", this.getAllChampionships().length);
                     this._cachePartLoad2(cacheType, isWebApiShouldBeCalled);
                  }
               },
               error: (e) => {
                  this.frameworkService.traceCache("_cachePartLoad1", "error requestCount", requestCount);
                  handleError("_cachePartLoad1", e);
                  this._cachePartLoadEndOnError();
               }
            }
         );
      }
      else {
         const championshipsDB = await db.championshipsDB.toArray();
         const versionningDB = await db.versionningDB.toArray();
         const workgroupsDB = await db.workgroupsDB.toArray();
         this._allChampionships = championshipsDB;
         this._allVersions = versionningDB;
         this._allWorkgroups = workgroupsDB;

         //-> Set filter to current championship
         
         this._currentChampionship = this.getAllChampionships().FirstOrDefault(i => i.isCurrent);
         this.frameworkService.traceCache("_cachePartLoad1", "_cachePartLoad2 CALL 2", cacheType + "/" + isWebApiShouldBeCalled);
         this.frameworkService.traceCache("_cachePartLoad1", "this.getAllChampionships().length", this.getAllChampionships().length);
         this._cachePartLoad2(cacheType, isWebApiShouldBeCalled);
      }
   }

   private async _cachePartLoad2(cacheType: number, isWebApiShouldBeCalled: boolean) {
      this.subjectService.VersionNumberSubject.next(this._allVersions[0]?.value.replace("SITE ", ""));

      if (this.getAllChampionships().length == 0)
      {
         this._cachePartLoadEndOnErrorCatching();
         return;
      }

      if (cacheType == enumCacheTypeLoad.fromWebAPI) {
         this.frameworkService.traceCache("_cachePartLoad2", "this.getAllChampionships().length", this.getAllChampionships().length);
         this._queryFiltersOnChampionship = [];
         this._queryFiltersOnChampionship.push(new queryFilter({ propertyName: "ChampionshipSysId", operation: enumQueryFilterOp.Equals, value: this.getAllChampionships().FirstOrDefault(i => i.isCurrent).sysId }));
         //<- Set filter to current championship

         let requestCount = 0;
         let forkJoinSource: Record<string, ObservableInput<any>> = {};
         await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbB[enumIndexDbB.homenews], this._queryFiltersOnChampionship);
         await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbB[enumIndexDbB.sportsclub], null);
         await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbB[enumIndexDbB.team], this._queryFiltersOnChampionship);
         await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbB[enumIndexDbB.user], this._queryFiltersOnChampionship);
         await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbB[enumIndexDbB.match], this._queryFiltersOnChampionship);
         await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbB[enumIndexDbB.ranking], this._queryFiltersOnChampionship);
         await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbB[enumIndexDbB.activity], this._queryFiltersOnChampionship);
         await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbB[enumIndexDbB.trainingplayer], this._queryFiltersOnChampionship);
         await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbB[enumIndexDbB.sponsor], this._queryFiltersOnChampionship);
         await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbB[enumIndexDbB.todo], null);
         await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbB[enumIndexDbB.buildingtrainingclosingday], this._queryFiltersOnChampionship);

         if (this.accessRightsService.isUserConnected && this.accessRightsService.isUserInComitee(this.storageService.getUserConnected())) {
            // (only for member logged and in comitee)
            await this._cacheAddToForkJoinSource(forkJoinSource, enumIndexDbB[enumIndexDbB.comiteemeeting], this._queryFiltersOnChampionship);
         }

         from(Object.entries(forkJoinSource)).pipe(
            mergeMap(([key, obs]) =>
               (obs as Observable<any>).pipe(
                  tap((response) => {
                     this.frameworkService.traceCache("_cachePartLoad2", "query executed", cacheType + "/" + key);
                     this._cacheGetFromForkJoinSource(key, response);
                     if (this.storageService.isDataPrivacyAccepted()) {
                        this.frameworkService.traceCache("_cachePartLoad2", "_cacheSaveToIndexDB", key);
                        this._cacheSaveToIndexDB(key);
                     }
                  })
               )
            )
         ).subscribe(
            {
               next: (v) => {
                  requestCount++;
                  if (requestCount === Object.keys(forkJoinSource).length) {
                     this.frameworkService.traceCache("_cachePartLoad2", "last query executed", requestCount);
                     this._cachePartLoadEnd(cacheType, isWebApiShouldBeCalled);
                  }
               },
               error: (error) => {
                  handleError("_cachePartLoad2", error);
                  this._cachePartLoadEndOnError();
               }
            }
         );
      }
      else {
         this.frameworkService.traceCache("_cachePartLoad2", "this.getAllChampionships().length", this.getAllChampionships().length);

         let promise = [];

         promise.push(db.homeNewsDB.toArray());
         promise.push(db.sportsClubDB.toArray());
         promise.push(db.teamsDB.toArray());
         promise.push(db.matchsDB.toArray());
         promise.push(db.rankingsDB.toArray());
         promise.push(db.activitiesDB.toArray());
         promise.push(db.trainingPlayersDB.toArray());
         promise.push(db.sponsorsDB.toArray());
         promise.push(db.buildingTrainingClosingDayDB.toArray());
         promise.push(db.usersDB.toArray());

         if (this.accessRightsService.isUserConnected && this.accessRightsService.isUserInComitee(this.storageService.getUserConnected())) {
            promise.push(db.comiteeMeetingsDB.toArray());
         }

         Promise.all(promise)
            .then((promise) => {
               this._allHomeNews = promise[0];
               this._sportsClub = promise[1][0];
               this._allTeams = promise[2];
               this._allMatchs = promise[3];
               this._allRankings = promise[4];
               this._allActivities = promise[5];
               this._allTrainingPlayers = promise[6];
               this._allSponsors = promise[7];
               this._allBuildingTrainingClosingDay = promise[8];
               this._allUsers = promise[9];
               if (this.accessRightsService.isUserConnected && this.accessRightsService.isUserInComitee(this.storageService.getUserConnected())) {
                  this._allComiteeMeetings = promise[10];
               }
               this.frameworkService.traceCache("_cachePartLoad2", "last query executed");
            })
            .catch(error => {
               //this.frameworkService.traceCache("_cachePartLoad2", "Failed to lopad data", error);
            })
            .finally(() => {
               this._cachePartLoadEnd(cacheType, isWebApiShouldBeCalled);
            });
      }
   }

   //CACHE END LOAD
   private async _cachePartLoadEnd(cacheType: number, isWebApiShouldBeCalled: boolean) {
      try {
         //Load 
         this._loadAllPreForeignKeys();
         //-> loadAllTeamsForeignKeys                               //Need championship list
         this._loadAllPreList();
         //-> _loadAllTeamsWithoutPicture                           //Need team list
         this._loadAllForeignKeys();
         //-> _loadAllUsersForeignKeys();                           //Need teamWithoutPicture list
         //-> _loadAllHomeNewsForeignKeys();                        //Need championship list
         //-> _loadAllSponsorsForeignKeys();                        //Need championship list
         //-> _loadAllMatchsForeignKeys();                          //Need championship list, teamWithoutPicture list
         //-> _loadAllRankingForeignKeys();                         //Need teamWithoutPicture list
         //-> _loadAllTrainingPlayersForeignKeys();                 //Need championship list, user list
         //-> _loadAllActivitiesForeignKeys();                      //Need championship list
         //-> _loadlAllBuildingTrainingsClosingDayForeignKeys();    //Need championship list, teamWithoutPicture list

         if (this.accessRightsService.isUserConnected && this.accessRightsService.isUserInComitee(this.storageService.getUserConnected())) {
            this._loadAllComiteeMeetingsForeignKeys();            //Need comiteeMeetings list

         }
         this._loadAllOthersList();
         //-> getAllMembersByAllTeam()                              //Need user list (profile, member)
         //-> getAllMembersByFirstname()                            //Need user list (profile, member)
         //-> getAllMembersByLastname()                             //Need user list (profile, member)
         //-> getAllMembersByRole()                                 //Need user list (profile, member, comiteemeeting, contactus)
         //-> getAllMembersByTeam()                                 //Need user list (profile, member)
         //-> _initializeAllTrainingPlayersList()
         //    -> _teamOfCurrentUser
         //    -> _allUsersOfTeamOfCurrentUser
         //    -> _coachOfCurrentLogged
         //    -> _allCoachsOfCurrentLogged
         //    -> _allTrainingPlayersOfCurrentLogged
         //    -> _allTrainingPlayersOfUserCoachConnected
         //-> _loadAllClubEvents()
         //    -> _allClubEvents
         //       -> Ajout des matchs officiels (CHAMPIONSHIP, HAINAUT_CUP) dans _allClubEvents (pour activités)
         //       -> Ajout des matchs NON officiels (FRIENDLY, TOURNAMENT, EXTRA_TRAINING) dans _allClubEvents (pour trainingPlayer)
         //       -> Ajout des réunions de comité (COMITEE_MEETING) dans _allClubEvents (pour activités)
         //       -> Ajout des anniversaires (BIRTHDAY) dans _allClubEvents (pour activités)
         //       -> Ajout des activités (SUPPER, ACTIVITY) dans _allClubEvents (pour activités)
         //-> If isUserConnected _loadAllClubEventsOfUserAuthenticated()  
         //    -> _allClubEventsOfUserAuthenticated
         //       -> Ajout des matchs officiels (CHAMPIONSHIP, HAINAUT_CUP) dans _allClubEventsOfUserAuthenticated (pour trainingPLayer)
         //       -> Ajout des matchs NON officiels (FRIENDLY, TOURNAMENT, EXTRA_TRAINING) dans _allClubEventsOfUserAuthenticated (pour trainingPlayer)
         //       -> Ajout des jours de fermeture (CLOSING) dans _allClubEvents (pour activités) et _allClubEventsOfUserAuthenticated (pour trainingPlayer)

         if (cacheType == enumCacheTypeLoad.fromWebAPI) {
            this.storageService.setLastResetCookie(this.frameworkService.getTodayString());
            this.storageService.setLastUpdateCookie(this.frameworkService.getTodayString());
         }
         this.reloadRefreshData(this.storageService.getUserConnected());

         this.subjectService.DataLastUpdateSubject.next(this.storageService.getLastUpdateCookie());
         this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
         this.subjectService.IsButtonInLoadingSubject.next(false);
         this.subjectService.GlobalCacheLoadedStatusSubject.next(enumCacheStatusLoad.loaded);
         this.frameworkService.traceCache("_cachePartLoadEnd", "END");
      }
      catch (error) {
         this.subjectService.IsButtonInLoadingSubject.next(false);
         this.subjectService.GlobalCacheLoadedStatusSubject.next(enumCacheStatusLoad.loaded);
         handleError("_cachePartLoadEnd", error);
      }
   }


   public async _cachePartLoadEndOnError() {
      try {
         this._loadAllPreForeignKeys()
         this._loadAllPreList();
         this._loadAllForeignKeys();
         this._loadAllOthersList();

         this.subjectService.IsButtonInLoadingSubject.next(false);
         this.subjectService.GlobalCacheLoadedStatusSubject.next(enumCacheStatusLoad.loaded);
      }
      catch (error) {
         this.subjectService.IsButtonInLoadingSubject.next(false);
         this.subjectService.GlobalCacheLoadedStatusSubject.next(enumCacheStatusLoad.loaded);
      }
   }

   public async _cachePartLoadEndOnErrorCatching() {
      this.subjectService.IsButtonInLoadingSubject.next(false);
      this.subjectService.GlobalCacheLoadedStatusSubject.next(enumCacheStatusLoad.loaded);
   }
   ////////////////// < CACHE LOAD

   ////////////////// CACHE SOURCE FOR WEB API
   private async _cacheAddToForkJoinSource(forkJoinSource: Record<string, ObservableInput<any>>, entityName: string, _queryFiltersOnChampionship: any[]) {
      switch (entityName) {
         case enumIndexDbA[enumIndexDbA.none]:
            forkJoinSource[entityName] = of(1);
            break;
         case enumIndexDbA[enumIndexDbA.refreshToken]:
            if (navigator.onLine) {
               forkJoinSource[entityName] = this.authenticationService.refreshToken(this.storageService.getTokenFromStorage());
            }
            else
               forkJoinSource[entityName] = of(1);
            break;
         case enumIndexDbA[enumIndexDbA.championship]:
            this.frameworkService.traceCache("_cacheAddToForkJoinSource", "championship");
            forkJoinSource[entityName] = this.championshipService.getAllActiveWithoutIncluding([]);
            break;
         case enumIndexDbA[enumIndexDbA.versionning]:
            this.frameworkService.traceCache("_cacheAddToForkJoinSource", "versionning");
            forkJoinSource[entityName] = this.versionningService.getAllVersions();
            break;
         case enumIndexDbA[enumIndexDbA.workgroup]:
            this.frameworkService.traceCache("_cacheAddToForkJoinSource", "workgroup");
            forkJoinSource[entityName] = this.workgroupService.getAllActiveWithoutIncluding([]);
            break;

         case enumIndexDbB[enumIndexDbB.sportsclub]:
            forkJoinSource[entityName] = this.sportsClubService.getAllActiveWithoutIncluding([]);
            break;
         case enumIndexDbB[enumIndexDbB.todo]:
            forkJoinSource[entityName] = this.todoService.getAllTodos();
            break;
         case enumIndexDbA[enumIndexDbA.championship]:
            forkJoinSource[entityName] = this.championshipService.getAllActiveWithoutIncluding([]);
            break;
         case enumIndexDbB[enumIndexDbB.buildingtrainingclosingday]:
            forkJoinSource[entityName] = this.buildingTrainingClosingDayService.getAllActiveWithoutIncluding(_queryFiltersOnChampionship);
            break;
         case enumIndexDbB[enumIndexDbB.homenews]:
            forkJoinSource[entityName] = this.homeNewsService.getAllWithoutIncluding(_queryFiltersOnChampionship);
            break;
         case enumIndexDbB[enumIndexDbB.team]:
            forkJoinSource[entityName] = this.teamService.getAllActiveWithoutIncluding(_queryFiltersOnChampionship);
            break;
         case enumIndexDbB[enumIndexDbB.sponsor]:
            forkJoinSource[entityName] = this.sponsorService.getAllActiveWithoutIncluding(_queryFiltersOnChampionship);
            break;
         case enumIndexDbB[enumIndexDbB.activity]:
            forkJoinSource[entityName] = this.activityService.getAllWithoutIncluding(_queryFiltersOnChampionship);
            break;
         case enumIndexDbB[enumIndexDbB.user]:
            forkJoinSource[entityName] = this.userService.getAllFromChampionship(_queryFiltersOnChampionship[0].value);
            break;
         case enumIndexDbB[enumIndexDbB.ranking]:
            forkJoinSource[entityName] = this.rankingService.getAllFromChampionship(_queryFiltersOnChampionship[0].value);
            break;
         case enumIndexDbB[enumIndexDbB.match]:
            forkJoinSource[entityName] = this.matchService.getAllActiveWithoutIncluding(_queryFiltersOnChampionship);
            break;
         case enumIndexDbB[enumIndexDbB.trainingplayer]:
            forkJoinSource[entityName] = this.trainingPlayerService.getAllActiveWithoutIncluding(_queryFiltersOnChampionship);
            break;
         case enumIndexDbB[enumIndexDbB.comiteemeeting]:
            forkJoinSource[entityName] = this.comiteeMeetingService.getAllActiveWithoutIncluding(_queryFiltersOnChampionship);
            break;
      }
   }

   ////////////////// CACHE GET DATA FROM WEB API
   private async _cacheGetFromForkJoinSource(entityName: string, data: any) {
      this.frameworkService.traceCache("cache", "_cacheGetFromForkJoinSource", entityName);
      try {
         switch (entityName) {
            case enumIndexDbA[enumIndexDbA.refreshToken]:
               if (data != null && data != 1) {
                  this.storageService.saveToken(data);
               }
               break;
            case enumIndexDbA[enumIndexDbA.championship]:
               this.frameworkService.traceCache("enumIndexDbA.championship", "data.value", data.value);
               this._allChampionships = this.encryptionService.decryptObject(false, data.value);
               break;
            case enumIndexDbA[enumIndexDbA.versionning]:
               this._allVersions = data.toString().replace('/\r\n/g', '\n').split('\n').map((x) => {
                  return { value: x };
               });
               break;
            case enumIndexDbA[enumIndexDbA.workgroup]:
               this._allWorkgroups = this.encryptionService.decryptObject(false, data.value);
               break;

            case enumIndexDbB[enumIndexDbB.sportsclub]:
               this._sportsClub = this.encryptionService.decryptObject(false, data.value)[0];
               break;
            case enumIndexDbB[enumIndexDbB.todo]:
               this._allTodos = data.toString().replace('/\r\n/g', '\n').split('\n').map((x) => {
                  return { value: x };
               });
               break;
            case enumIndexDbB[enumIndexDbB.buildingtrainingclosingday]:
               this._allBuildingTrainingClosingDay = this.encryptionService.decryptObject(false, data.value);
               break;
            case enumIndexDbB[enumIndexDbB.homenews]:
               this._allHomeNews = this.encryptionService.decryptObject(false, data.value);
               break;
            case enumIndexDbB[enumIndexDbB.team]:
               this._allTeams = this.encryptionService.decryptObject(false, data.value);
               break;
            case enumIndexDbB[enumIndexDbB.sponsor]:
               this._allSponsors = (this.encryptionService.decryptObject(false, data.value)).OrderBy(i => i.order).ToArray();

               break;
            case enumIndexDbB[enumIndexDbB.activity]:
               this._allActivities = this.encryptionService.decryptObject(false, data.value);
               break;
            case enumIndexDbB[enumIndexDbB.user]:
               this._allUsers = this.encryptionService.decryptObject(false, data.value);
               break;
            case enumIndexDbB[enumIndexDbB.ranking]:
               this._allRankings = this.encryptionService.decryptObject(false, data.value);
               break;
            case enumIndexDbB[enumIndexDbB.match]:
               this._allMatchs = this.encryptionService.decryptObject(false, data.value);
               break;
            case enumIndexDbB[enumIndexDbB.trainingplayer]:
               this._allTrainingPlayers = this.encryptionService.decryptObject(false, data.value);
               break;
            case enumIndexDbB[enumIndexDbB.comiteemeeting]:
               if (this.accessRightsService.isUserConnected && this.accessRightsService.isUserInComitee(this.storageService.getUserConnected())) {
                  this._allComiteeMeetings = this.encryptionService.decryptObject(false, data.value);
               }
               break;
         }
      }
      catch (error) {
         //this.frameworkService.traceCache("_cacheGetFromForkJoinSource", "CACHE ERROR", error);
      }
   }

   ////////////////// CACHE SAVE DATA TO INDEXDB
   private async _cacheSaveToIndexDB(entityName: string) {
      switch (entityName) {
         case enumIndexDbA[enumIndexDbA.versionning]:
            if (db != undefined && db.versionningDB != undefined && this.getAllVersions()) {
               db.versionningDB.clear();
               db.versionningDB.bulkAdd(this.getAllVersions());
            }
            break;
         case enumIndexDbA[enumIndexDbA.championship]:
            if (db != undefined && db.championshipsDB != undefined && this.getAllChampionships()) {
               db.championshipsDB.clear();
               db.championshipsDB.bulkAdd(this.getAllChampionships());
            }
            break;
         case enumIndexDbA[enumIndexDbA.workgroup]:
            if (db != undefined && db.workgroupsDB != undefined && this.getAllWorkgroups()) {
               db.workgroupsDB.clear();
               db.workgroupsDB.bulkAdd(this.getAllWorkgroups());
            }
            break;

         case enumIndexDbB[enumIndexDbB.sportsclub]:
            if (db != undefined && db.sportsClubDB != undefined && this.getSportsClub()) {
               db.sportsClubDB.clear();
               db.sportsClubDB.add(this.getSportsClub(), this.getSportsClub().sysId);
            }
            break;
         case enumIndexDbB[enumIndexDbB.todo]:
            if (db != undefined && db.todoDB != undefined && this.getAllTodos()) {
               db.todoDB.clear();
               db.todoDB.bulkAdd(this.getAllTodos());
            }
            break;

         case enumIndexDbB[enumIndexDbB.buildingtrainingclosingday]:
            if (db != undefined && db.buildingTrainingClosingDayDB != undefined && this.getAllBuildingTrainingsClosingDay()) {
               db.buildingTrainingClosingDayDB.clear();
               db.buildingTrainingClosingDayDB.bulkAdd(this.getAllBuildingTrainingsClosingDay());
            }
            break;
         case enumIndexDbB[enumIndexDbB.homenews]:
            if (db != undefined && db.homeNewsDB != undefined && this.getAllHomeNews()) {
               db.homeNewsDB.clear();
               db.homeNewsDB.bulkAdd(this.getAllHomeNews());
            }
            break;
         case enumIndexDbB[enumIndexDbB.team]:
            if (db != undefined && db.teamsDB != undefined && this.getAllTeams()) {
               db.teamsDB.clear();
               db.teamsDB.bulkAdd(this.getAllTeams());
            }
            break;
         case enumIndexDbB[enumIndexDbB.sponsor]:
            if (db != undefined && db.sponsorsDB != undefined && this.getAllSponsors()) {
               db.sponsorsDB.clear();
               db.sponsorsDB.bulkAdd(this.getAllSponsors());
            }
            break;
         case enumIndexDbB[enumIndexDbB.activity]:
            if (db != undefined && db.activitiesDB != undefined && this.getAllActivities()) {
               db.activitiesDB.clear();
               db.activitiesDB.bulkAdd(this.getAllActivities());
            }
            break;
         case enumIndexDbB[enumIndexDbB.user]:
            if (db != undefined && db.usersDB != undefined && this.getAllUsers()) {
               db.usersDB.clear();
               db.usersDB.bulkAdd(this.getAllUsers());
            }
            break;
         case enumIndexDbB[enumIndexDbB.ranking]:
            if (db != undefined && db.rankingsDB != undefined && this.getAllRankings()) {
               db.rankingsDB.clear();
               db.rankingsDB.bulkAdd(this.getAllRankings());
            }
            break;
         case enumIndexDbB[enumIndexDbB.match]:
            if (db != undefined && db.matchsDB != undefined && this.getAllMatchs()) {
               db.matchsDB.clear();
               db.matchsDB.bulkAdd(this.getAllMatchs());
            }
            break;
         case enumIndexDbB[enumIndexDbB.trainingplayer]:
            if (db != undefined && db.trainingPlayersDB != undefined && this.getAllTrainingPlayers()) {
               db.trainingPlayersDB.clear();
               db.trainingPlayersDB.bulkAdd(this.getAllTrainingPlayers(), { allKeys: true });
            }
            break;
         case enumIndexDbB[enumIndexDbB.comiteemeeting]:
            if (db != undefined && db.comiteeMeetingsDB != undefined && this.getAllComiteeMeetings()) {
               db.comiteeMeetingsDB.clear();
               db.comiteeMeetingsDB.bulkAdd(this.getAllComiteeMeetings());
            }
            break;
      }
   }

   private _loadAllTeamsWithoutPicture() {
      let teamSysIds = [];
      this._allTeamsWithoutPicture = [];
      this.getAllTeams().forEach(i => {
         teamSysIds.push(i.sysId);
         let t = new team();
         t.clone(i);
         t.picture = "";
         this._allTeamsWithoutPicture.push(t);
      });
   }

   private _loadAllClubEvents() {
      // PRE-LOAD ALL EVENTS OF THE CLUB <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
      this._allClubEvents.clear();

      //-> Ajout des matchs officiels (CHAMPIONSHIP, HAINAUT_CUP) dans _allClubEvents (pour activités)
      let allTeamsTemp = this.getAllTeamsWithoutPicture().filter(i => i.division != "" && i.sysId > 1);
      allTeamsTemp.forEach(t => {
         let allMatchsOfTeam = this.getAllMatchs().filter(m =>
            !m.fromTeam.includes(this.frameworkService.getByeStatus()) &&
            !m.awayTeam.includes(this.frameworkService.getByeStatus()) &&
            (m.fromTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()) ||
               m.awayTeam.toLowerCase().includes(this.frameworkService.getSportsClubName())) &&
            m.teamSysId == t.sysId);
         allMatchsOfTeam.forEach(m => {
            this._allClubEvents.set(this.frameworkService.getDateFormatYyyyMmDd(m.day) + m.sysId + m.type, new eventClub({ clubActivityType: this.frameworkService.getClubActivityType(m.type), day: m.day, availability: enumAvailability.UNKNOWN, reason: "", event: m }));
         });
      });
      //<- Ajout des matchs officiels (CHAMPIONSHIP, HAINAUT_CUP) dans _allClubEvents (pour activités)

      //-> Ajout des matchs NON officiels (FRIENDLY, TOURNAMENT, EXTRA_TRAINING) dans _allClubEvents (pour trainingPlayer)
      let allTrainingPlayersOfCoachs = this.getAllTrainingPlayers().filter(i => this.getAllCoachsOfClub().map(u => u.sysId).includes(i.playerSysId));

      for (let cpt = 0; cpt < allTrainingPlayersOfCoachs?.length; cpt++) {
         let m = new match();
         m.sysId = 0;
         if (allTrainingPlayersOfCoachs[cpt].extraTrainingIsAtHome)
            m.awayTeam = allTrainingPlayersOfCoachs[cpt].extraTrainingTeam;
         else
            m.fromTeam = allTrainingPlayersOfCoachs[cpt].extraTrainingTeam;
         m.day = allTrainingPlayersOfCoachs[cpt].day;
         m.time = allTrainingPlayersOfCoachs[cpt].extraTrainingTimeStarting;
         if (allTrainingPlayersOfCoachs[cpt].extraTrainingTimeEnding != "00:00" && allTrainingPlayersOfCoachs[cpt].extraTrainingTimeEnding != "")
            m.time += " à " + allTrainingPlayersOfCoachs[cpt].extraTrainingTimeEnding;
         m.isAtHome = allTrainingPlayersOfCoachs[cpt].extraTrainingIsAtHome;
         m.type = allTrainingPlayersOfCoachs[cpt].type;
         let u = this.getAllUsers().FirstOrDefault(i => i.sysId == allTrainingPlayersOfCoachs[cpt].playerSysId);
         m.teamSysId = u.teamSysId;
         m = this.loadMatchsForeignKeys(m);
         m.buildingName = allTrainingPlayersOfCoachs[cpt].extraTrainingBuilding;
         m.status = "AJO";
         this._allClubEvents.set(this.frameworkService.getDateFormatYyyyMmDd(m.day) + m.teamSysId + m.type, new eventClub({ clubActivityType: this.frameworkService.getClubActivityType(m.type), tpSysId: m.sysId, day: m.day, availability: enumAvailability.PRESENT, reason: "", event: m }));
      }
      //<- Ajout des matchs NON officiels (FRIENDLY, TOURNAMENT, EXTRA_TRAINING) dans _allClubEvents (pour trainingPlayer)

      //-> Ajout des réunions de comité (COMITEE_MEETING) dans _allClubEvents (pour activités)
      let allComiteeMeetingOverToday = this.getAllComiteeMeetings().filter(i => this.frameworkService.isDayOverToday(i.date));
      if (allComiteeMeetingOverToday != null) {
         for (let cpt = 0; cpt < allComiteeMeetingOverToday.length; cpt++) {
            //TODO JD Ajouter le possibilité d'entrer ses présences pour les réunions
            this._allClubEvents.set(this.frameworkService.getDateFormatYyyyMmDd(allComiteeMeetingOverToday[cpt].date) + allComiteeMeetingOverToday[cpt].sysId + enumClubActivityType[enumClubActivityType.COMITEE_MEETING], new eventClub({ clubActivityType: enumClubActivityType.COMITEE_MEETING, day: allComiteeMeetingOverToday[cpt].date, availability: enumAvailability.UNKNOWN, reason: "", event: allComiteeMeetingOverToday[cpt] }));
         }
      }
      //<- Ajout des réunions de comité (COMITEE_MEETING) dans _allClubEvents (pour activités)

      //-> Ajout des anniversaires (BIRTHDAY) dans _allClubEvents (pour activités)
      let allBirthday = this.getAllUsersWithActiveLogin();
      if (allBirthday != null) {
         for (let cpt = 0; cpt < allBirthday.length; cpt++) {
            let birthdayDateEvent = this.frameworkService.getCurrentDateOfBirthdayDate(allBirthday[cpt].birthdayDate);
            this._allClubEvents.set(this.frameworkService.getDateFormatYyyyMmDd(birthdayDateEvent) + allBirthday[cpt].sysId + enumClubActivityType[enumClubActivityType.BIRTHDAY], new eventClub({ clubActivityType: enumClubActivityType.BIRTHDAY, day: birthdayDateEvent, availability: enumAvailability.UNKNOWN, reason: "", event: allBirthday[cpt] }));
         }
      }
      //<- Ajout des anniversaires (BIRTHDAY) dans _allClubEvents (pour activités)

      //-> Ajout des activités (SUPPER, ACTIVITY) dans _allClubEvents (pour activités)
      //  _allActivitees[cpt] = 10 -> clubActivityType = enumClubActivityType.SUPPER
      //  _allActivitees[cpt] = 12 -> clubActivityType = enumClubActivityType.AG_MEETING
      //  _allActivitees[cpt] = 13 -> clubActivityType = enumClubActivityType.TOURNAMENT_INFO
      let allActiviteesOverToday = this.getAllActiveActivities().filter(i => this.frameworkService.isDayOverToday(i.day));
      if (allActiviteesOverToday != null) {
         for (let cpt = 0; cpt < allActiviteesOverToday.length; cpt++) {
            let type = allActiviteesOverToday[cpt].type;
            this._allClubEvents.set(this.frameworkService.getDateFormatYyyyMmDd(allActiviteesOverToday[cpt].day) + allActiviteesOverToday[cpt].sysId + enumClubActivityType[type], new eventClub({ clubActivityType: type, day: allActiviteesOverToday[cpt].day, availability: enumAvailability.UNKNOWN, reason: "", event: allActiviteesOverToday[cpt] }));
         }
      }
      //<- Ajout des activités (SUPPER, ACTIVITY) dans _allClubEvents (pour activités)
   }

   private _initializeAllTrainingPlayersList() {
      if (this.accessRightsService.isUserConnected) {
         // Equipe Id du joueur connecté
         this._teamOfCurrentUser = this.getAllTeamsWithoutPicture().FirstOrDefault(i => i.sysId == this.storageService.getUserConnected().teamSysId);
      }
      else {
         this._teamOfCurrentUser = null;
      }

      if (this.getAllUsersWithActiveLogin() != null && this.accessRightsService.isUserConnected) {
         // Liste des joueurs avec login actif de l'équipe du membre connecté
         this._allUsersOfTeamOfCurrentUser = this.getAllUsersWithActiveLogin().filter(i => i.teamSysId == this.storageService.getUserConnected().teamSysId);
      }
      if (this.getAllUsers() != null && this._teamOfCurrentUser != null) {
         // Coach du joueur connecté
         this._coachOfCurrentLogged = this.getAllUsers().FirstOrDefault(i => i.teamSysId == this._teamOfCurrentUser?.sysId && this.accessRightsService.isMemberCoachOnly(i));
         // Tous les coachs du club
         this._allCoachsOfCurrentLogged = this.getAllUsers().filter(i => i.teamSysId == this._teamOfCurrentUser?.sysId && (this.frameworkService.IsMemberCoachs(i)));
      }

      if (this.accessRightsService.isUserConnected) {
         this._allTrainingPlayersOfCurrentLogged = this.getAllTrainingPlayers().filter(i => i.playerSysId == this.storageService.getUserConnected().sysId);
         if (this._coachOfCurrentLogged != null)
            this._allTrainingPlayersOfUserCoachConnected = this.getAllTrainingPlayers().filter(i => i.playerSysId == this._coachOfCurrentLogged.sysId);
      }
      else {
         this._allTrainingPlayersOfCurrentLogged = [];
         this._allTrainingPlayersOfUserCoachConnected = [];
      }
   }

   private _loadAllClubEventsOfUserAuthenticated() {
      this._allClubEventsOfUserAuthenticated.clear();

      //-> Ajout des matchs officiels (CHAMPIONSHIP, HAINAUT_CUP) dans _allClubEventsOfUserAuthenticated (pour trainingPLayer)
      if (this.getAllMatchs() != null) {
         let allMatchsOfTeamAndClub = this.getAllMatchs().filter(i => i.fromTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()) ||
            i.awayTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()));

         this._allMatchsOfUserTeamAndClub = allMatchsOfTeamAndClub.filter(i => i.teamSysId == this._teamOfCurrentUser?.sysId);

         this._allMatchsOfUserTeamAndClub.forEach(m => {
            // Mise à jour des absences et des raisons d'absence pour les matchs officiels,
            let tp = this.getAllTrainingPlayersOfCurrentLogged().FirstOrDefault(j => this.frameworkService.getDateFormatYyyyMmDd(j.day) == this.frameworkService.getDateFormatYyyyMmDd(m.day));

            if (tp != null) {
               let availability = this.getAvailabilityFromTrainingPlayer(tp);
               this._allClubEventsOfUserAuthenticated.set(this.frameworkService.getDateFormatYyyyMmDd(m.day) + m.type, new eventClub({ clubActivityType: this.frameworkService.getClubActivityType(m.type), tpSysId: tp.sysId, day: m.day, availability: availability, reason: tp.reason, event: m }));
            }
            else {
               this._allClubEventsOfUserAuthenticated.set(this.frameworkService.getDateFormatYyyyMmDd(m.day) + m.type, new eventClub({ clubActivityType: this.frameworkService.getClubActivityType(m.type), tpSysId: 0, day: m.day, availability: enumAvailability.PRESENT, reason: "", event: m }));
            }
         });
      }
      //<- Ajout des matchs officiels (CHAMPIONSHIP, HAINAUT_CUP) dans _allClubEventsOfUserAuthenticated (pour trainingPLayer)

      //-> Ajout des matchs NON officiels (FRIENDLY, TOURNAMENT, EXTRA_TRAINING) dans _allClubEventsOfUserAuthenticated (pour trainingPlayer)
      if (this._allTrainingPlayersOfUserCoachConnected != null) {
         for (let cpt = 0; cpt < this._allTrainingPlayersOfUserCoachConnected?.length; cpt++) {
            if (this._allTrainingPlayersOfUserCoachConnected[cpt].type == enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY] ||
               this._allTrainingPlayersOfUserCoachConnected[cpt].type == enumActivityType[enumActivityType.EXTRA_TRAINING_DAY] ||
               this._allTrainingPlayersOfUserCoachConnected[cpt].type == enumActivityType[enumActivityType.TOURNAMENT_DAY]) {
               let m = new match();
               m.sysId = 0;
               if (this._allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingIsAtHome)
                  m.awayTeam = this._allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingTeam;
               else
                  m.fromTeam = this._allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingTeam;
               m.day = this._allTrainingPlayersOfUserCoachConnected[cpt].day;
               m.time = this._allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingTimeStarting;
               if (this._allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingTimeEnding != "00:00" && this._allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingTimeEnding != "")
                  m.time += " à " + this._allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingTimeEnding;
               m.isAtHome = this._allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingIsAtHome;
               m.type = this._allTrainingPlayersOfUserCoachConnected[cpt].type;
               let u = this.getAllUsers().FirstOrDefault(i => i.sysId == this._allTrainingPlayersOfUserCoachConnected[cpt].playerSysId);
               m.teamSysId = u.teamSysId;
               m.team = this.getAllTeamsWithoutPicture().FirstOrDefault(i => i.sysId == u.teamSysId);
               m.buildingName = this._allTrainingPlayersOfUserCoachConnected[cpt].extraTrainingBuilding;
               m.status = "AJO";

               this._allMatchsOfUserTeamAndClub.push(m);

               // Mise à jour des absences et des raisons d'absence pour les matchs non officiels via les tp du joueurs (pas du coach) !
               let tp = this._allTrainingPlayersOfCurrentLogged.FirstOrDefault(j => this.frameworkService.getDateFormatYyyyMmDd(j.day) == this.frameworkService.getDateFormatYyyyMmDd(m.day));
               if (tp != null) {
                  m.sysId = tp.sysId;
                  let availability = this.getAvailabilityFromTrainingPlayer(tp);
                  this._allClubEventsOfUserAuthenticated.set(this.frameworkService.getDateFormatYyyyMmDd(tp.day) + m.type, new eventClub({ clubActivityType: this.frameworkService.getClubActivityType(m.type), tpSysId: tp.sysId, day: m.day, availability: availability, reason: tp.reason, event: m }));
               }
               else {
                  m.sysId = this._allTrainingPlayersOfUserCoachConnected[cpt].sysId;
                  this._allClubEventsOfUserAuthenticated.set(this.frameworkService.getDateFormatYyyyMmDd(m.day) + m.type, new eventClub({ clubActivityType: this.frameworkService.getClubActivityType(m.type), tpSysId: m.sysId, day: m.day, availability: enumAvailability.PRESENT, reason: "", event: m }));
               }
            }
         }
      }
      //<- Ajout des matchs NON officiels (FRIENDLY, TOURNAMENT, EXTRA_TRAINING) dans _allClubEventsOfUserAuthenticated (pour trainingPlayer)

      //-> Ajout des jours de fermeture (CLOSING) dans _allClubEvents (pour activités) et _allClubEventsOfUserAuthenticated (pour trainingPlayer)
      let allClosingDay = this.getAllBuildingTrainingsClosingDay();
      for (let cpt = 0; cpt < allClosingDay?.length; cpt++) {
         if (allClosingDay[cpt].teamSysId == 1 || (this.storageService.getUserConnected()?.teamSysId == allClosingDay[cpt].teamSysId || this.storageService.getUserConnected()?.team2SysId == allClosingDay[cpt].teamSysId)) {
            let m = new match();
            m.sysId = allClosingDay[cpt].sysId;
            m.day = allClosingDay[cpt].day;
            m.type = enumActivityType[enumActivityType.CLOSING_DAY];
            this._allMatchsOfUserTeamAndClub.push(m);
            this._allClubEventsOfUserAuthenticated.set(this.frameworkService.getDateFormatYyyyMmDd(m.day) + enumClubActivityType[enumClubActivityType.CLOSING], new eventClub({ clubActivityType: enumClubActivityType.CLOSING, tpSysId: 0, day: m.day, availability: enumAvailability.UNKNOWN, reason: allClosingDay[cpt].reason, event: m }));
            this._allClubEvents.set(this.frameworkService.getDateFormatYyyyMmDd(m.day) + m.sysId + enumClubActivityType[enumClubActivityType.CLOSING], new eventClub({ clubActivityType: enumClubActivityType.CLOSING, tpSysId: 0, day: m.day, availability: enumAvailability.UNKNOWN, reason: allClosingDay[cpt].reason, event: m }));
         }
      }
      //<- Ajout des jours de fermeture (CLOSING) dans _allClubEvents (pour activités) et _allClubEventsOfUserAuthenticated (pour trainingPlayer)

   }

   private _loadAllPreForeignKeys() {
      this.loadAllTeamsForeignKeys(this.getAllTeams());        //Need championship list
   }

   private _loadAllPreList() {
      this._loadAllTeamsWithoutPicture();                       //Need team list
   }

   private _loadAllForeignKeys() {
      this._loadAllUsersForeignKeys();                          //Need teamWithoutPicture list
      this._loadAllHomeNewsForeignKeys();                       //Need championship list
      this._loadAllSponsorsForeignKeys();                       //Need championship list
      this._loadAllMatchsForeignKeys();                         //Need championship list, teamWithoutPicture list
      this._loadAllRankingForeignKeys();                        //Need teamWithoutPicture list
      this._loadAllTrainingPlayersForeignKeys();                //Need championship list, user list
      this._loadAllActivitiesForeignKeys();                     //Need championship list
      this._loadlAllBuildingTrainingsClosingDayForeignKeys();   //Need championship list, teamWithoutPicture list

      if (this.accessRightsService.isUserConnected && this.accessRightsService.isUserInComitee(this.storageService.getUserConnected())) {
         this._loadAllComiteeMeetingsForeignKeys();
      }
   }

   private _loadAllOthersList() {
      this._allMembersForMainTeam = this.getAllUsers();
      this._allMembersForSecondaryTeam = this._getAllMembersForAllSecondaryTeam();   // _getAllMembersForAllSecondaryTeam -> getAllUsers called
      this._allMembersByFirstname = this._getAllMembersByFirstname();   // getAllUsers called
      this._allMembersByLastname = this._getAllMembersByLastname();     // getAllUsers called
      this._allMembersByRole = this._getAllMembersByRole();             // getAllUsers called
      this._allMembersByTeam = this._getAllMembersByTeam();             // getAllUsers called

      this._initializeAllTrainingPlayersList();
      this._loadAllClubEvents();
      if (this.accessRightsService.isUserConnected)
         this._loadAllClubEventsOfUserAuthenticated();
   }

   //GET ALL
   public getAllMembersByAllTeam() {

      return this._allMembersForMainTeam.concat(this._allMembersForSecondaryTeam)
         .sort(function (a, b) {
            if ((a.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team?.teamCategory) + a.firstName + a.lastName < (b.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team?.teamCategory) + b.firstName + b.lastName) {
               return -1;
            }
            if ((a.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team?.teamCategory) + a.firstName + a.lastName > (b.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team?.teamCategory) + b.firstName + b.lastName) {
               return 1;
            }
            return 0;
         });
   }

   public getAllMembersForMainTeam() {
      return this._allMembersForMainTeam
         .sort(function (a, b) {
            if ((a.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team?.teamCategory) + a.firstName + a.lastName < (b.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team?.teamCategory) + b.firstName + b.lastName) {
               return -1;
            }
            if ((a.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team?.teamCategory) + a.firstName + a.lastName > (b.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team?.teamCategory) + b.firstName + b.lastName) {
               return 1;
            }
            return 0;
         });
   }

   public getAllMembersByFirstname() {
      return this._allMembersByFirstname;
   }

   public getAllMembersByLastname() {
      return this._allMembersByLastname;
   }

   public getAllMembersByRole() {
      return this._allMembersByRole;
   }

   public getAllMembersByTeam() {
      return this._allMembersByTeam;
   }

   public getAllChampionships() {
      return this._allChampionships == null ? [] : this._allChampionships;
   }
   public getCurrentChampionship() {
      return this._currentChampionship;
   }
   public getPreviousChampionship() {
      return this._previousChampionship
   }

   public getSportsClub() {
      return this._sportsClub;
   }

   public getAllActivities() {
      return this._allActivities == null ? [] : this._allActivities;
   }
   public getAllActiveActivities() {
      return this.getAllActivities() == null ? [] : this.getAllActivities().filter(i => i.sysIsActive);
   }

   public getAllHomeNews() {
      return this._allHomeNews == null ? [] : this._allHomeNews;
   }
   public getAllActiveHomeNews() {
      return this._allHomeNews == null ? [] : this.getAllHomeNews().filter(i => i.sysIsActive);
   }

   public getAllBuildingTrainingsClosingDay() {
      return this._allBuildingTrainingClosingDay == null ? [] : this._allBuildingTrainingClosingDay;
   }

   public getAllClubEvents() {
      return this._allClubEvents;
   }

   public getAllClubEventsOfUserAuthenticated() {
      return this._allClubEventsOfUserAuthenticated;
   }

   public getAllCoachsOfClub() {
      return this._allUsers == null ? [] : this.getAllUsers().filter(i => this.accessRightsService.isMemberCoachOnly(i));
   }

   public getAllCoachsOfCurrentLogged() {
      return this._allCoachsOfCurrentLogged == null ? [] : this._allCoachsOfCurrentLogged;
   }

   public getAllComiteeMeetings() {
      return this._allComiteeMeetings == null ? [] : this._allComiteeMeetings;
   }

   public getAllMatchs() {
      return this._allMatchs == null ? [] : this._allMatchs;
   }
   public getAllMatchsOfUserTeamAndClub() {
      // Liste des matchs officiel, non officiel (amical + tournoi), jours de fermeture du club de l'équipe du joueur connecté"
      return this._allMatchsOfUserTeamAndClub == null ? [] : this._allMatchsOfUserTeamAndClub;
   }

   public getAllRankings() {
      return this._allRankings == null ? [] : this._allRankings;
   }

   public getAllSponsors() {
      return this._allSponsors == null ? [] : this._allSponsors;
   }

   public getAllTeams() {
      return this._allTeams == null ? [] : this._allTeams;
   }
   public getAllTeamsWithoutPicture() {
      return this._allTeamsWithoutPicture == null ? [] : this._allTeamsWithoutPicture;
   }

   public getAllTodos() {
      return this._allTodos == null ? [] : this._allTodos;
   }

   public getAllTrainingDays() {
      return this._allTrainingDays == null ? [] : this._allTrainingDays;
   }

   public getAllTrainingPlayers() {
      return this._allTrainingPlayers == null ? [] : this._allTrainingPlayers;
   }
   public getAllTrainingPlayersOfCurrentLogged() {
      return this._allTrainingPlayersOfCurrentLogged == null ? [] : this._allTrainingPlayersOfCurrentLogged;
   }
   public getAllTrainingPlayersOfUserCoachConnected() {
      return this._allTrainingPlayersOfUserCoachConnected == null ? [] : this._allTrainingPlayersOfUserCoachConnected;
   }

   public getAllUsers() {
      return this._allUsers == null ? [] : this._allUsers;
   }
   public getAllUsersOfTeamOfCurrentUser() {
      return this._allUsersOfTeamOfCurrentUser == null ? [] : this._allUsersOfTeamOfCurrentUser;
   }
   public getAllUsersWithActiveLogin() {
      return this.getAllUsers() == null ? [] : this.getAllUsers().filter(i => !i.isUserLoginInactive);
   }

   public getAllMembersForAllSecondaryTeam() {
      let allUsers = this.getAllUsers();
      let temp = [];

      if (allUsers == null)
         return temp;

      for (let cpt = 0; cpt < allUsers.length; cpt++) {
         if (allUsers[cpt].team2 != null) {
            let p = Object.assign({}, allUsers[cpt]);

            let t1 = Object.assign({}, allUsers[cpt].team);
            let t2 = Object.assign({}, allUsers[cpt].team2);

            p.team = t2;
            p.teamSysId = allUsers[cpt].team2SysId;
            p.team2 = t1;
            p.team2SysId = allUsers[cpt].teamSysId;
            temp.push(Object.assign({}, p));
         }
      }
      return temp;
   }

   public getAllUsersWithAllTeams(isTeamSysInverted: boolean) {
      let allUsers = this.getAllUsers();
      let temp = [];

      if (allUsers == null)
         return temp;

      for (let cpt = 0; cpt < allUsers.length; cpt++) {
         if (allUsers[cpt].team2 == null) {
            temp.push(Object.assign({}, allUsers[cpt]));
         }
         else {
            temp.push(Object.assign({}, allUsers[cpt]));

            if (isTeamSysInverted) {
               let p = Object.assign({}, allUsers[cpt]);

               let t1 = Object.assign({}, allUsers[cpt].team);
               let t2 = Object.assign({}, allUsers[cpt].team2);

               p.team = t2;
               p.teamSysId = allUsers[cpt].team2SysId;
               p.team2 = t1;
               p.team2SysId = allUsers[cpt].teamSysId;
               temp.push(Object.assign({}, p));
            }
         }
      }
      return temp;
   }

   public getAllVersions() {
      return this._allVersions == null ? [] : this._allVersions;
   }

   public getAllWorkgroups() {
      return this._allWorkgroups == null ? [] : this._allWorkgroups;
   }

   //FOREIGN KEYS
   private _loadlAllBuildingTrainingsClosingDayForeignKeys() {
      this.getAllBuildingTrainingsClosingDay().forEach(b => b = this.loadBuildingTrainingsClosingDayForeignKeys(b));
   }
   private _loadAllActivitiesForeignKeys() {
      this.getAllActivities().forEach(r => r = this.loadActivitiesForeignKeys(r));
   }
   private _loadAllTrainingPlayersForeignKeys() {
      this.getAllTrainingPlayers().forEach(r => r = this.loadTrainingPlayersForeignKeys(r));
   }
   private _loadAllComiteeMeetingsForeignKeys() {
      this.getAllComiteeMeetings().forEach(c => c = this.loadComiteeMeetingsForeignKeys(c));
   }
   private _loadAllHomeNewsForeignKeys() {
      this.getAllHomeNews().forEach(r => r = this.loadHomeNewsForeignKeys(r));
   }
   private _loadAllMatchsForeignKeys() {
      this.getAllMatchs().forEach(t => t = this.loadMatchsForeignKeys(t));
   }
   private _loadAllRankingForeignKeys() {
      this.getAllRankings().forEach(r => r = this.loadRankingsForeignKeys(r));
   }
   private _loadAllSponsorsForeignKeys() {
      this.getAllSponsors().forEach(r => r = this.loadSponsorsForeignKeys(r));
   }
   public loadAllTeamsForeignKeys(_allTeams) {
      this.getAllTeams().forEach(t => t = this.loadTeamsForeignKeys(t));
   }
   private _loadAllUsersForeignKeys() {
      this.getAllUsers().forEach(u => u = this.loadUsersForeignKeys(u));
   }

   public loadActivitiesForeignKeys(activity) {
      activity.championship = this.getAllChampionships().FirstOrDefault(t => t.sysId == activity.championshipSysId);
      return activity;
   }
   public loadBuildingTrainingsClosingDayForeignKeys(buildingTrainingClosingDay) {
      buildingTrainingClosingDay.team = this.getAllTeamsWithoutPicture().FirstOrDefault(t => t.sysId == buildingTrainingClosingDay.teamSysId);
      buildingTrainingClosingDay.championship = this.getAllChampionships().FirstOrDefault(c => c.sysId == buildingTrainingClosingDay.championshipSysId);
      return buildingTrainingClosingDay;
   }
   private loadTrainingPlayersForeignKeys(trainingPlayer: trainingPlayer) {
      trainingPlayer.championship = this.getAllChampionships().FirstOrDefault(p => p.sysId == trainingPlayer.championshipSysId);
      trainingPlayer.player = this.getAllUsers().FirstOrDefault(p => p.sysId == trainingPlayer.playerSysId);
      return trainingPlayer;
   }
   public loadTeamsForeignKeys(team) {
      team.championship = this.getAllChampionships().FirstOrDefault(c => c.sysId == team.championshipSysId);
      return team;
   }
   public loadUsersForeignKeys(user) {
      user.team = this.getAllTeamsWithoutPicture().FirstOrDefault(t => t.sysId == user.teamSysId);
      user.team2 = this.getAllTeamsWithoutPicture().FirstOrDefault(j => j.sysId == user.team2SysId);
      user.workgroup = this.getAllWorkgroups().FirstOrDefault(j => j.sysId == user.workgroupSysId);
      return user;
   }
   public loadComiteeMeetingsForeignKeys(comiteeMeetings) {
      comiteeMeetings.championship = this.getAllChampionships().FirstOrDefault(c => c.sysId == comiteeMeetings.championshipSysId);
      return comiteeMeetings;
   }
   public loadHomeNewsForeignKeys(homeNews) {
      homeNews.championship = this.getAllChampionships().FirstOrDefault(t => t.sysId == homeNews.championshipSysId);
      return homeNews;
   }
   public loadMatchsForeignKeys(match) {
      match.championship = this.getAllChampionships().FirstOrDefault(c => c.sysId == match.championshipSysId);
      match.team = this.getAllTeamsWithoutPicture().FirstOrDefault(t => t.sysId == match.teamSysId);
      return match;
   }
   public loadRankingsForeignKeys(ranking) {
      ranking.team = this.getAllTeamsWithoutPicture().FirstOrDefault(t => t.sysId == ranking.teamSysId);
      return ranking;
   }
   public loadSponsorsForeignKeys(sponsor) {
      sponsor.championship = this.getAllChampionships().FirstOrDefault(t => t.sysId == sponsor.championshipSysId);
      return sponsor;
   }

   // public raiseIsPrivacyPopupClosedSubject() {
   //    this.subjectService.IsPrivacyPopupClosedSubject.next(true);
   // }

   public showPrivacyPopup() {
      const dialogData = new DataPrivacyDialogModel("Protection de vos données");

      if (this.storageService.getDataPrivacyCookie() == null) {
         const dialogRef = this.dialog.open(DataPrivacyDialogComponent, {
            maxWidth: "100%",
            width: "100%",
            maxHeight: "100%",
            height: "100%",
            data: dialogData
         });

         //CACHE POPUP PRIVACY (AFTER close)
         dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult) {
               //Set privacy cookie
               this.storageService.setDataPrivacyCookie(true);
            }
            else {
               this.resetData();
            }
            this.subjectService.IsPrivacyPopupClosedSubject.next(true);
         });
      }
   }

   public getAvailabilityFromTrainingPlayer(tp: trainingPlayer) {
      if (tp == null)
         return enumAvailability.PRESENT;
      return this.getAllTrainingPlayers() == null ? enumAvailability.PRESENT : (tp.present == null) ? enumAvailability.GUARD : (tp.present >= 1 ? enumAvailability.PRESENT : enumAvailability.NOTPRESENT);
   }

   public getCoachOfCurrentLogged() {
      return this._coachOfCurrentLogged;
   }

   public getEventClubWithTraining(allClubEventsOfUserAuthenticated, date: Date): eventClub {
      let ec = this.frameworkService.findFirstEventClub(allClubEventsOfUserAuthenticated, this.frameworkService.getDateFormatYyyyMmDd(date))?.eventClub;
      if (ec == null || ec.event == null) {
         // Ajout de l'event si c'est un jour d'entraînement avec update des présences sinon on update les présences sur l'event existant
         ec = this.getTrainingEventClubObject(ec, date);
      }
      return ec;
   }

   public getExtraTrainingOfCoachFromDate(date: Date): trainingPlayer {
      return this.getAllTrainingPlayersOfUserCoachConnected().FirstOrDefault(i =>
         this.frameworkService.getDateFormatYyyyMmDd(i.day) == this.frameworkService.getDateFormatYyyyMmDd(date) &&
         i.type != "" && i.type != null && i.playerSysId == this.frameworkService.getSysIdOfUser(this.getCoachOfCurrentLogged()) &&
         this.frameworkService.getMatchTrainingActivitiesType().FirstOrDefault(j => j.value === i.type));
   }

   public getSportClub() {
      return this._sportsClub;
   }

   public getTeamOfUserConnected() {
      return this._teamOfCurrentUser;
   }

   public getToday() {
      return this.frameworkService.getToday();
   }

   public getTrainingEventClubObject(ec, date): eventClub {
      let trainingDay = this.getAllTrainingDays().FirstOrDefault(i => i.day == new Date(date).getDay());
      let tp = this.getAllTrainingPlayersOfCurrentLogged().FirstOrDefault(i => this.frameworkService.getDateFormatYyyyMmDd(date) == this.frameworkService.getDateFormatYyyyMmDd(i.day));

      //If it is a training day, check if it is re_ally one (no other event exists on this date)
      let tempEc = this.frameworkService.findFirstEventClub(this.getAllClubEventsOfUserAuthenticated(), this.frameworkService.getDateFormatYyyyMmDd(date));
      let isAtrainingDay = trainingDay != null;
      if (isAtrainingDay) {
         if (tempEc != null && tempEc.eventClub != null && tempEc.eventClub.clubActivityType != enumClubActivityType.TRAINING_DAY)
            isAtrainingDay = false;
      }

      if (isAtrainingDay) {
         //It is a training day
         ec = new eventClub();
         ec.tpSysId = null;

         ec.clubActivityType = enumClubActivityType.TRAINING_DAY;
         ec.day = date;
         ec.event = new match();
         ec.event.sysId = null;
         ec.event.name = enumActivityType[enumActivityType.TRAINING_DAY];
         ec.event.day = date;
         ec.event.time = trainingDay.timeFrom + " à " + trainingDay.timeTo;
         ec.event.type = enumActivityType[enumActivityType.TRAINING_DAY];
         ec.event.buildingName = trainingDay.buildingTraining;

         if (tp != null) {
            let availability = this.getAvailabilityFromTrainingPlayer(tp);

            ec.tpSysId = ec.event.sysId = tp.sysId; // <-- Keep sysId of trainingPlayer for update
            if (availability == enumAvailability.GUARD) {
               ec.reason = enumAvailabilityReason[enumAvailabilityReason.GUARD];
               ec.availability = enumAvailability.GUARD;
            }
            else {
               if (availability == enumAvailability.PRESENT) {
                  ec.reason = "";
                  ec.availability = enumAvailability.PRESENT;
               }
               else {
                  ec.reason = tp.reason;
                  ec.availability = enumAvailability.NOTPRESENT;
               }
            }
         }
         else {
            ec.tpSysId = 0;
            ec.availability = enumAvailability.PRESENT;
         }
      }
      else {
         //It is NOT a training day
         if (ec != null) {
            ec.tpSysId = tp.sysId;
            ec.availability = enumAvailability.UNKNOWN;
         }
      }
      return ec;
   }

   private _getAllMembersForAllSecondaryTeam() {
      return this.getAllMembersForAllSecondaryTeam().filter(i => i.sysId != 1 && i.sysId != 2);
   }

   private _getAllMembersByFirstname() {
      let allPlayers = this.getAllUsers().filter(i => i.sysId != 1 && i.sysId != 2).sort(function (a, b) {
         if (a.firstName + a.lastName < b.firstName + b.lastName) {
            return -1;
         }
         if (a.firstName + a.lastName > b.firstName + b.lastName) {
            return 1;
         }
         return 0;
      });

      return allPlayers;
   }

   private _getAllMembersByLastname() {
      let allPlayers = this.getAllUsers().filter(i => i.sysId != 1 && i.sysId != 2).sort(function (a, b) {
         if (a.lastName + a.firstName < b.lastName + b.firstName) {
            return -1;
         }
         if (a.lastName + a.firstName > b.lastName + b.firstName) {
            return 1;
         }
         return 0;
      });

      return allPlayers;
   }

   private _getAllMembersByRole(): Map<string, object> {
      let list = new Map<string, object>();
      let allUsers = this.getAllUsers();
      if (allUsers != null)
         allUsers = allUsers.filter(i => i.sysId != 1 && i.sysId != 2).sort(function (a, b) {
            if (a.firstName + a.lastName < b.firstName + b.lastName) {
               return -1;
            }
            if (a.firstName + a.lastName > b.firstName + b.lastName) {
               return 1;
            }
            return 0;
         });
      allUsers.forEach(u => this.frameworkService.getProfileRoles(u, false, false).forEach(i => {
         let temp = [];
         temp = list.get(i) as user[];
         if (temp == undefined) {
            temp = [];
         }
         temp.push(u);
         list.set(i, temp);
      }
      ));

      return list;
   }
   private _getAllMembersByTeam() {

      let allPlayers = this.getAllUsers().filter(i => i.sysId != 1 && i.sysId != 2).sort(function (a, b) {
         if ((a.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team?.teamCategory) + a.lastName + a.firstName < (b.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team?.teamCategory) + b.lastName + b.firstName) {
            return -1;
         }
         if ((a.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team?.teamCategory) + a.lastName + a.firstName > (b.team?.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team?.teamCategory) + b.lastName + b.firstName) {
            return 1;
         }
         return 0;
      });

      return allPlayers;
   }

   public convertQueryControllerToPublicFiltersOnChampionship() {
      return this._queryFiltersOnChampionship;
   }








   public manageIUClubEventsOfUserAuthenticated(date: Date, tp, eventClubSource: any, insert: boolean, user: user): Map<string, eventClub> {
      let trainingDay = this.getAllTrainingDays().FirstOrDefault(i => i.day == new Date(date).getDay());
      let ec: any = new eventClub();

      //If it is a training day, check if it is re_ally one (no other event exists on this date)
      let tempEc = this.frameworkService.findFirstEventClub(this.getAllClubEventsOfUserAuthenticated(), this.frameworkService.getDateFormatYyyyMmDd(date));
      let isAtrainingDay = trainingDay != null;
      if (isAtrainingDay) {
         if (tempEc != null && tempEc.eventClub != null && tempEc.eventClub.clubActivityType != enumClubActivityType.TRAINING_DAY)
            isAtrainingDay = false;
      }

      if (isAtrainingDay && tp.type != enumActivityType[enumActivityType.EXTRA_TRAINING_DAY] &&
         tp.type != enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY] &&
         tp.type != enumActivityType[enumActivityType.TOURNAMENT_DAY]) {
         //It is a training day
         ec.tpSysId = tp.sysId;
         ec.clubActivityType = enumClubActivityType.TRAINING_DAY;
         ec.day = date;
         ec.event = new match();
         ec.event.sysId = tp.sysId;
         ec.event.name = enumActivityType[enumActivityType.TRAINING_DAY];
         ec.event.day = date;
         ec.event.time = trainingDay.timeFrom + " à " + trainingDay.timeTo;
         ec.event.type = enumActivityType[enumActivityType.TRAINING_DAY];
         ec.event.buildingName = trainingDay.buildingTraining;
         ec.reason = tp.reason;
         ec.availability = (tp.present == null) ? enumAvailability.GUARD : (tp.present >= 1 ? enumAvailability.PRESENT : enumAvailability.NOTPRESENT);
      }
      else {
         //It is NOT a training day
         if ((user.sysId == tp.playerSysId && eventClubSource != null) || user.sysId == this.storageService.getUserConnected().sysId) {
            ec = eventClubSource;
            ec.tpSysId = tp.sysId;

            //It is an EXTRA training day ?
            if (tp.type == enumActivityType[enumActivityType.EXTRA_TRAINING_DAY] ||
               tp.type == enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY] ||
               tp.type == enumActivityType[enumActivityType.TOURNAMENT_DAY]) {
               ec.reason = tp.reason;
               ec.availability = (tp.present == null) ? enumAvailability.GUARD : (tp.present >= 1 ? enumAvailability.PRESENT : enumAvailability.NOTPRESENT);
            }
            else {
               ec.reason = tp.reason;
               ec.availability = (tp.present == null) ? enumAvailability.GUARD : (tp.present >= 1 ? enumAvailability.PRESENT : enumAvailability.NOTPRESENT);
            }
         }
      }

      if (insert) {
         if (this.storageService.isDataPrivacyAccepted()) db.trainingPlayersDB.add(tp, tp.sysId);
         this.getAllTrainingPlayers().Add(tp);
      }
      else {
         if (this.storageService.isDataPrivacyAccepted()) db.trainingPlayersDB.update(tp, tp.sysId);
         this.getAllTrainingPlayers()[this.getAllTrainingPlayers().findIndex(i => i.sysId === tp.sysId)] = tp;
      }

      this._initializeAllTrainingPlayersList();

      //99=90
      //this.storageService.getUserConnected() == tp.playerSysId && has been removed from condition
      if ((eventClubSource != null)) {
         this.getAllClubEventsOfUserAuthenticated().set(this.frameworkService.getDateFormatYyyyMmDd(tp.day) + tp.type, ec);
      }
      return this.getAllClubEventsOfUserAuthenticated();
   }

   public manageRClubEventsOfUserAuthenticated(tp: any, user: user) {
      if (this.accessRightsService.isMemberCoachOnly(user)) {

         //Remove TP from cache
         this.getAllTrainingPlayers().Remove(tp);
         //Remove TP from IndexDB
         if (this.storageService.isDataPrivacyAccepted()) db.trainingPlayersDB.delete(tp.sysId);

         this._initializeAllTrainingPlayersList();

         //Remove _all eventClub
         this.getAllClubEventsOfUserAuthenticated().delete(this.frameworkService.getDateFormatYyyyMmDd(tp.day) + tp.type);
      }
      else {
         this.getAllTrainingPlayers().Remove(tp);
         this._initializeAllTrainingPlayersList();

         if (this.storageService.isDataPrivacyAccepted()) db.trainingPlayersDB.delete(tp.sysId);
         if (tp.type == enumClubActivityType[enumClubActivityType.TRAINING_DAY] || (this.accessRightsService.isMemberCoachOnly(user)))
            this.getAllClubEventsOfUserAuthenticated().delete(this.frameworkService.getDateFormatYyyyMmDd(tp.day) + tp.type);
         else {
            let ec = this.frameworkService.findFirstEventClub(this.getAllClubEventsOfUserAuthenticated(), this.frameworkService.getDateFormatYyyyMmDd(tp.day));
            ec.eventClub.tpSysId = 0;
            ec.eventClub.reason = "";
            ec.eventClub.availability = enumAvailability.PRESENT;
            this.getAllClubEventsOfUserAuthenticated().set(this.frameworkService.getDateFormatYyyyMmDd(tp.day) + tp.type, ec.eventClub);
         }
      }

      return this.getAllClubEventsOfUserAuthenticated();
   }

   public manageRIUComiteeMeeting(comiteeMeeting, operation?: boolean) {
      switch (operation) {
         //Delete
         case null:
            {
               if (this.storageService.isDataPrivacyAccepted()) db.comiteeMeetingsDB.delete(comiteeMeeting.sysId);
               this.getAllComiteeMeetings().Remove(comiteeMeeting);
               break;
            }
         //Insert
         case true:
            {
               comiteeMeeting = this.loadComiteeMeetingsForeignKeys(comiteeMeeting);
               if (this.storageService.isDataPrivacyAccepted()) db.comiteeMeetingsDB.add(comiteeMeeting, comiteeMeeting.sysId);
               this.getAllComiteeMeetings().Add(comiteeMeeting);
               break;
            }
         //Update
         case false:
            {
               if (this.storageService.isDataPrivacyAccepted()) db.comiteeMeetingsDB.update(comiteeMeeting, comiteeMeeting.sysId);
               this.getAllComiteeMeetings()[this.getAllComiteeMeetings().findIndex(i => i.sysId === comiteeMeeting.sysId)] = comiteeMeeting;
               break;
            }
      }
   }

   public manageUUser(user) {
      user = this.loadUsersForeignKeys(user);
      if (this.storageService.isDataPrivacyAccepted() && db.usersDB != undefined)
         db.usersDB.update(user, user.sysId);
      this.getAllUsers()[this.getAllUsers().findIndex(i => i.sysId === user.sysId)] = user;
   }

   public reloadRefreshData(userLogged: user) {
      this._allTrainingDays = [];
      let teamOfUserConnected = this.getTeamOfUserConnected();
      if (teamOfUserConnected != null) { // If at least one team is selected ...
         //-> Add training days
         if (teamOfUserConnected.trainingDay1 != -1) this._allTrainingDays.push({ day: teamOfUserConnected.trainingDay1, timeFrom: teamOfUserConnected.trainingTimeFrom1, timeTo: teamOfUserConnected.trainingTimeTo1, buildingTraining: teamOfUserConnected.buildingTrainingName1 });
         if (teamOfUserConnected.trainingDay2 != -1) this._allTrainingDays.push({ day: teamOfUserConnected.trainingDay2, timeFrom: teamOfUserConnected.trainingTimeFrom2, timeTo: teamOfUserConnected.trainingTimeTo2, buildingTraining: teamOfUserConnected.buildingTrainingName2 });
         if (teamOfUserConnected.trainingDay3 != -1) this._allTrainingDays.push({ day: teamOfUserConnected.trainingDay3, timeFrom: teamOfUserConnected.trainingTimeFrom3, timeTo: teamOfUserConnected.trainingTimeTo3, buildingTraining: teamOfUserConnected.buildingTrainingName3 });
         //<- Add training days
      }

      this.getAllMatchsOfUserTeamAndClub().sort((a, b) => a.day < b.day ? -1 : 1);
   }


}