//This file is autogenerated by C# GeneratedModels

import { jsonIgnore } from "json-ignore";
import { auditFields } from "src/sites/core/models/system/auditFields";
import { metadataModelBase } from "src/sites/core/models/system/metadataModelBase";
import { enumControlType, enumWorkgroup } from "../../core/enum/Enum";
import { championship } from "./championship";

@Reflect.metadata('homeNewsBase', 'homeNewsBase')
export class homeNewsBase extends auditFields {

   @Reflect.metadata('header',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         },
         editControlType: enumControlType.Html
      })
   header: string = "";

   @Reflect.metadata('description',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Textarea,
         editVariableValidator: {
         }
      })
   description: string = "";

   @Reflect.metadata('icon',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   icon: string = "";

   @Reflect.metadata('picture',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Picture
      })
   picture: string = "";

   @Reflect.metadata('isShouldAuthenticated',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         },
         editControlType: enumControlType.Bool2States
      })
   isShouldAuthenticated: boolean = false;

   @Reflect.metadata('isCritical',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         },
         editControlType: enumControlType.Bool2States
      })
   isCritical: boolean = false;

   @Reflect.metadata('order',
   <metadataModelBase> <unknown> {
     editVariableValidator: {
     },
     editControlType: enumControlType.Integer
   })
   order : number = 0;

   @Reflect.metadata('championshipSysId',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Dropdown,
         editDropdownEntityMapping: "championship",
         editDropdownPropertiesForDisplay: "yearStarting;yearEnding",
         editDropdownFormatForDisplay: "{0}-{1}",
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.UserEdit],
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None]
      })
   championshipSysId: number = 0;

   @Reflect.metadata('championship',
      <metadataModelBase><unknown>{
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None]
      })
   @jsonIgnore()
   championship: championship;


}