import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, ObservableInput, catchError, forkJoin, throwError, timeout } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/sites/core/components/confirmDialog/confirmDialog.component';
import { enumCacheStatusLoad, enumCacheTypeLoad } from 'src/sites/core/enum/Enum';
import { user } from 'src/sites/core/models/user/user';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { AccountService } from 'src/sites/core/services/account.service';
import { AppConfigService } from 'src/sites/core/services/appconfig.service';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { LastUpdatesService } from 'src/sites/core/services/lastUpdates.service';
import { NotificationService } from 'src/sites/core/services/notification.service';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { SystemService } from 'src/sites/core/services/system.service';
import { encryptedValue } from 'src/sites/vabourlettis/authentication/encryptedValue';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';

@Component({
   selector: 'app-navbar',
   templateUrl: './navbar.component.html',
   styleUrls: ['./navbar.component.scss'],
   providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
   public headerSiteTitleDisplay: string = "";
   public headerSiteUnderTitleDisplay: string = "";
   public iconOnlyToggled = false;
   public isDisplayOnAMobile = false;
   public isNetworkStatusOnline: boolean = false;
   public isTurnAdminButtonsOff: boolean = true;
   public searchTitleDisplay: string = "";
   public sidebarToggled = false;
   public userInitialDisplay: string = "";
   public userNameDisplay: string = "";
   public userConnectedSubject?: user = undefined;
   private subjectHubConnectionStatus: number = 0;
   public isSynchronisationRunning: boolean = false;

   constructor(
      private router: Router,
      public subjectService: SubjectService,
      public accessRightsService: AccessRightsService,
      private accountService: AccountService,
      private translateService: TranslateService,
      private cacheService: CacheService,
      private appConfigService: AppConfigService,
      private systemService: SystemService,
      private frameworkService: FrameworkService,
      private lastUpdatesService: LastUpdatesService,
      public storageService: StorageService,
      public dialog: MatDialog,
      config: NgbDropdownConfig) {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });
      this.subjectService.IsWindowResizingSubject.subscribe(value => { this.isDisplayOnAMobile = value; this.translatePage(); });
      this.subjectService.IsTurnOffAdminButtonSubject.next(this.isTurnAdminButtonsOff);
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
      this.subjectService.HubConnectionStatusSubject.subscribe(value => { this.subjectHubConnectionStatus = value; });
      this.subjectService.UserConnectedSubject.subscribe(x => { this.userConnectedSubject = x; this.translatePage(); });
      this.subjectService.IsSynchronisationInProgressSubject.subscribe(value => {
         if (value != null) {
            if (value == 2) {
               this.frameworkService.displayAlert(true, "La synchronisation a bien été effectuée.");
            }
            else {
               if (value == 1)
               {
                  this.frameworkService.displayAlert(true, "La synchronisation a bien été effectuée avec mises à jour des données.");
               }
               else
                  this.frameworkService.displayAlert(false, "La synchronisation n'a pas été effectuée.");
            }
            this.subjectService.IsButtonInLoadingSubject.next(false);
            this.isSynchronisationRunning = false;
         }
      }
      );

      this.getScreenSize();
      config.placement = 'bottom-right';
   }

   @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.subjectService.IsWindowResizingSubject.next(window.innerWidth <= 960);
   }

   public ngOnInit(): void {
   }

   public backColorEnv() {
      if (this.appConfigService.getEnvironment() == "development")
         return "navbarMiddle pageBodyEnvDev";
      else {
         if (this.appConfigService.getEnvironment() == "test")
            return "navbarMiddle pageBodyEnvTest";
         else
            return "navbarMiddle";
      }
   }

   public fvwbSynchronize(methods: number) {
      this.isSynchronisationRunning = true;

      const message1: string = "Voulez-vous effectuer la mise à jour ?";
      const message2: string = "";

      const dialogData = new ConfirmDialogModel("Synchronisation FVWB", message1, message2);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
         maxWidth: "100%",
         width: "100%",
         data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
         if (dialogResult) {
            let appServiceArray: Observable<any>[] = [];
            appServiceArray.push(this.systemService.fvwbSynchronize(false));

            forkJoin(appServiceArray).subscribe({
               next: async data => {
               },
               error: err => {
                  this.subjectService.IsSynchronisationInProgressSubject.next(0);
               }
               });
         }
         else {
            this.subjectService.IsSynchronisationInProgressSubject.next(0);
         }
      });
   }



   public getCurrentChampionshipName() {
      if (this.cacheService.getCurrentChampionship() == null)
         return "indisponible";
      else
         return this.cacheService.getCurrentChampionship()?.yearStarting + " - " + this.cacheService.getCurrentChampionship()?.yearEnding
   }

   public getWorkerServiceClass() {
      return "material-icons status" + this.subjectHubConnectionStatus.toString();
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });
   }

   public isBackgroundOpaque() {
      return (this.appConfigService.getEnvironment() == "development");
   }

   public isInformationEditable() {
      return this.accessRightsService.isInformationEditable;
   }

   // public isUserConnected(): boolean {
   //    let result = this.userConnectedSubject != null;
   //    if (result) {
   //       let user = this.userConnectedSubject as user | undefined

   //       if (user) {
   //          this.translateService.use(user.language);
   //       }
   //       this.accessRightsService.refresh();
   //    }
   //    return result;
   // }

   public login() {
      this.router.navigate(['/login']);
   }

   public logout() {
      const message1: string = "Voulez-vous vous déconnecter ?";
      const message2: string = "";

      const dialogData = new ConfirmDialogModel("", message1, message2);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
         maxWidth: "100%",
         width: "100%",
         data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
         if (dialogResult) {
            //LOGOUT
            //- remove the TOKEN;
            //- set language to fr
            //- UserConnectedSubject to null
            //- navigate to home
            //- Remove collection not used in cache on public mode
            this.accountService.signOut();
            this.cacheService.resetUnloggedData();
         }
         this.subjectService.IsButtonInLoadingSubject.next(false);
      });
   }

   public reloadWebsite() {
      if (!this.subjectService.IsGlobalCacheInLoading) {
         this.cacheService.cacheFullReload(true);
      }
   }

   public refreshWebsite() {
      window.location.replace(window.location.href.replace('http', !window.location.href.includes("localhost:4300") ? 'https' : 'http'));
      window.location.reload();
   }

   public setReset() {
      this.lastUpdatesService.setReset().subscribe({
         next: async data => {
            if (data) {
               this.frameworkService.displayAlert(true, "Le flag \"reset\" a bien été mis à jour.");
            }
            else {
               this.frameworkService.displayAlert(false, "Le flag \"reset\" n'a pas été mis à jour.");
            }
        },
        error: err => {
            this.subjectService.IsButtonInLoadingSubject.next(false);
            this.frameworkService.displayAlert(null, err);
        }
      });
   }

   public site() {
      return this.appConfigService.getSiteName();
   }

   // toggle sidebar in small devices
   public toggleOffcanvas() {
      document.querySelector('.sidebar-offcanvas')?.classList.toggle('active');
   }

   // toggle sidebar
   public toggleSidebar() {
      let body = document.querySelector('body');

      this.iconOnlyToggled = !this.iconOnlyToggled;
      if (this.iconOnlyToggled) {
         body?.classList.add('sidebar-icon-only');
      } else {
         body?.classList.remove('sidebar-icon-only');
      }
   }

   public translatePage() {
      //SearchTitle
      this.translateService.get(['SearchTitle'])
         .subscribe(translations => {
            this.searchTitleDisplay = translations['SearchTitle'];
         });

      //HeaderSiteTitle
      this.translateService.get(['HeaderSiteTitle'])
         .subscribe(translations => {
            this.headerSiteTitleDisplay = translations['HeaderSiteTitle'];
         });

      //UsernameUnknown
      this.translateService.get('UsernameUnknown').subscribe((res: string) => {
         if (this.userConnectedSubject == null) {
            this.userNameDisplay = res;
            this.userInitialDisplay = "";
         }
         else {
            this.userNameDisplay = this.userConnectedSubject.firstName;
            this.userInitialDisplay = this.userConnectedSubject.firstName;
         }
      });
   }

   public turnOnOffAdminButtons() {
      this.isTurnAdminButtonsOff = !this.isTurnAdminButtonsOff;
      this.subjectService.IsTurnOffAdminButtonSubject.next(this.isTurnAdminButtonsOff);
   }




   // toggle right sidebar
   // toggleRightSidebar() {
   //   document.querySelector('#right-sidebar').classList.toggle('open');
   // }
}
