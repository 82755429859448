<nav class="sidebar sidebar-offcanvas" id="sidebar">
   <div class="noPrint">

      <ul class="nav">
         <li class="nav-item" [ngClass]="{ 'active': club.isActive }">
            <a class="nav-link" routerLink="/club" routerLinkActive #club="routerLinkActive" (click)="toggleMenu()">
               <span class="menu-title">{{SportsclubTitleDisplay}}</span>
               <span class="material-icons" style="margin-left:auto">festival</span>
            </a>
         </li>

         <li class="nav-item" [ngClass]="{ 'active': trainingInfo.isActive }">
            <a class="nav-link" routerLink="/trainingInfo" routerLinkActive #trainingInfo="routerLinkActive"
               (click)="toggleMenu()">
               <span class="menu-title">{{trainingInfoTitleDisplay}}</span>
               <span class="material-icons" style="margin-left:auto">directions_run</span>
            </a>
         </li>

         <li *ngIf="accessRightsService.isUserConnected" class="nav-item">
            <a class="nav-link" (click)="uiComiteeCollapsed = !uiComiteeCollapsed"
               [attr.aria-expanded]="uiComiteeCollapsed" aria-controls="ui-comitee" id="subMenu">
               <span class="menu-title" id="subMenu">
                  {{comiteeTitleDisplay}}</span>
               <span class="material-icons" style="margin-left:auto"><span
                     class='material-icons'>keyboard_arrow_down</span> <i class="menu-arrow"></i> group_work</span>
            </a>
            <div class="collapse" id="ui-comitee" [ngbCollapse]="!uiComiteeCollapsed">
               <ul class="nav flex-column sub-menu">
                  <li class="nav-item" [ngClass]="{ 'active': comiteeMember.isActive }">
                     <a class="nav-link" routerLink="/comiteeMember" routerLinkActive #comiteeMember="routerLinkActive"
                        (click)="toggleMenu()">
                        <span class="menu-title">{{comiteeMemberTitleDisplay}}</span>
                        <span class="material-icons" style="margin-left:auto">supervisor_account</span>
                     </a>
                  </li>
                  <li *ngIf="accessRightsService.isUserConnectedInComitee()" class="nav-item"
                     [ngClass]="{ 'active': comiteeMeeting.isActive }">
                     <a class="nav-link" routerLink="/comiteeMeeting" routerLinkActive
                        #comiteeMeeting="routerLinkActive" (click)="toggleMenu()">
                        <span class="menu-title">{{comiteeMeetingTitleDisplay}}</span>
                        <span class="material-icons" style="margin-left:auto">event_note</span>
                     </a>
                  </li>
                  <!-- <li *ngIf="accessRightsService.isUserConnectedInComitee() && isTodoComiteeMeetingAvailable()" class="nav-item"
                     [ngClass]="{ 'active': comiteeTodo.isActive }">
                     <a class="nav-link" routerLink="/comiteeTodo" routerLinkActive #comiteeTodo="routerLinkActive"
                        (click)="toggleMenu()">
                        <span class="menu-title">{{comiteeTodoTitleDisplay}}</span>
                        <span class="material-icons" style="margin-left:auto">pending_actions</span>
                     </a>
                  </li> -->
               </ul>
            </div>
         </li>

         <!-- <li *ngIf="accessRightsService.isUserConnectedInComitee()" class="nav-item" [ngClass]="{ 'active': finances.isActive }">
            <a class="nav-link" routerLink="/finances" routerLinkActive #finances="routerLinkActive"
               (click)="toggleMenu()">
               <span class="menu-title">{{financesTitleDisplay}}</span>
               <span class="material-icons" style="margin-left:auto">savings</span>
            </a>
         </li> -->

         <li class="nav-item" [ngClass]="{ 'active': sponsor.isActive }">
            <a class="nav-link" routerLink="/sponsor" routerLinkActive #sponsor="routerLinkActive"
               (click)="toggleMenu()">
               <span class="menu-title">{{sponsorTitleDisplay}}</span>
               <span class="material-icons" style="margin-left:auto">volunteer_activism</span>
            </a>
         </li>

         <li class="nav-item" [ngClass]="{ 'active': newsPaper.isActive }">
            <a class="nav-link" routerLink="/newsPaper" routerLinkActive #newsPaper="routerLinkActive"
               (click)="toggleMenu()">
               <span class="menu-title">{{newsPaperTitleDisplay}}</span>
               <span class="material-icons" style="margin-left:auto">auto_stories</span>
            </a>
         </li>

         <li class="nav-item" [ngClass]="{ 'active': contactus.isActive }">
            <a class="nav-link" routerLink="/contactus" routerLinkActive #contactus="routerLinkActive"
               (click)="toggleMenu()">
               <span class="menu-title">{{contactusTitleDisplay}}</span>
               <span class="material-icons" style="margin-left:auto">phone</span>
            </a>
         </li>

         <li *ngIf="accessRightsService.isUserConnected && (accessRightsService.isUserConnectedAdministratorOrSupervisor() || accessRightsService.isUserConnectedSecretary())" class="nav-item">
            <a class="nav-link" (click)="uiManagementCollapsed = !uiManagementCollapsed" [attr.aria-expanded]="uiManagementCollapsed"
               aria-controls="ui-basic" id="subMenu">
               <span class="menu-title" id="subMenu">
                  {{managementListTitleDisplay}}</span>
               <span class="material-icons" style="margin-left:auto"><span
                     class='material-icons'>keyboard_arrow_down</span> <i class="menu-arrow"></i> manage_accounts</span>
            </a>
            <div class="collapse" id="ui-basic" [ngbCollapse]="!uiManagementCollapsed">
               <ul class="nav flex-column sub-menu">
                  <li *ngIf="accessRightsService.isUserConnected" class="nav-item"
                  [ngClass]="{ 'active': adminmemberlist.isActive }">
                  <a class="nav-link" routerLink="/adminmemberlist" routerLinkActive
                     #adminmemberlist="routerLinkActive" (click)="toggleMenu()">
                     <span class="menu-title">{{adminMemberListTitleDisplay}}</span>
                     <span class="material-icons" style="margin-left:auto">sports_volleyball</span>
                  </a>
                  </li>
                  <li *ngIf="accessRightsService.isUserConnected" class="nav-item"
                  [ngClass]="{ 'active': adminhomeNewslist.isActive }">
                  <a class="nav-link" routerLink="/adminhomeNewslist" routerLinkActive
                     #adminhomeNewslist="routerLinkActive" (click)="toggleMenu()">
                     <span class="menu-title">{{adminHomeNewsListTitleDisplay}}</span>
                     <span class="material-icons" style="margin-left:auto">telegram</span>
                  </a>
                  </li>
                  <li *ngIf="accessRightsService.isUserConnected" class="nav-item"
                  [ngClass]="{ 'active': adminbuildingtrainingClosingDaylist.isActive }">
                  <a class="nav-link" routerLink="/adminbuildingtrainingClosingDaylist" routerLinkActive
                     #adminbuildingtrainingClosingDaylist="routerLinkActive" (click)="toggleMenu()">
                     <span class="menu-title">{{adminBuildingTrainingClosingDayListTitleDisplay}}</span>
                     <span class="material-icons" style="margin-left:auto">door_front</span>
                  </a>
                  </li>
                  <li *ngIf="accessRightsService.isUserConnected" class="nav-item"
                     [ngClass]="{ 'active': adminactivitylist.isActive }">
                     <a class="nav-link" routerLink="/adminactivitylist" routerLinkActive
                        #adminactivitylist="routerLinkActive" (click)="toggleMenu()">
                        <span class="menu-title">{{adminActivitiesListTitleDisplay}}</span>
                        <span class="material-icons" style="margin-left:auto">upcoming</span>
                     </a>
                  </li>
                  <li *ngIf="accessRightsService.isUserConnected" class="nav-item"
                     [ngClass]="{ 'active': adminsponsorlist.isActive }">
                     <a class="nav-link" routerLink="/adminsponsorlist" routerLinkActive
                        #adminsponsorlist="routerLinkActive" (click)="toggleMenu()">
                        <span class="menu-title">{{adminSponsorsListTitleDisplay}}</span>
                        <span class="material-icons" style="margin-left:auto">volunteer_activism</span>
                     </a>
                  </li>
                  <li *ngIf="accessRightsService.isUserConnected" class="nav-item" [ngClass]="{ 'active': adminimportdata.isActive }">
                     <a class="nav-link" routerLink="/adminimportdata" routerLinkActive
                        #adminimportdata="routerLinkActive" (click)="toggleMenu()">
                        <span class="menu-title">{{adminImportDataTitleDisplay}}</span>
                        <span class="material-icons" style="margin-left:auto">upload</span>
                     </a>
                  </li>
               </ul>
            </div>
         </li>

         <li *ngIf="accessRightsService.isUserConnected && accessRightsService.isUserConnectedAdministratorOrSupervisor()" class="nav-item">
            <a class="nav-link" (click)="uiAdminCollapsed = !uiAdminCollapsed" [attr.aria-expanded]="uiAdminCollapsed"
               aria-controls="ui-basic" id="subMenu">
               <span class="menu-title" id="subMenu">
                  {{adminListTitleDisplay}}</span>
               <span class="material-icons" style="margin-left:auto"><span
                     class='material-icons'>keyboard_arrow_down</span> <i class="menu-arrow"></i> settings</span>
            </a>
            <div class="collapse" id="ui-basic" [ngbCollapse]="!uiAdminCollapsed">
               <ul class="nav flex-column sub-menu">
                  <li *ngIf="accessRightsService.isUserConnected" class="nav-item"
                     [ngClass]="{ 'active': adminchampionshiplist.isActive }">
                     <a class="nav-link" routerLink="/adminchampionshiplist" routerLinkActive
                        #adminchampionshiplist="routerLinkActive" (click)="toggleMenu()">
                        <span class="menu-title">{{adminChampionshipsListTitleDisplay}}</span>
                        <span class="material-icons" style="margin-left:auto">calendar_view_month</span>
                     </a>
                  </li>
                  <li *ngIf="accessRightsService.isUserConnected" class="nav-item" [ngClass]="{ 'active': adminteamlist.isActive }">
                     <a class="nav-link" routerLink="/adminteamlist" routerLinkActive #adminteamlist="routerLinkActive"
                        (click)="toggleMenu()">
                        <span class="menu-title">{{adminTeamsListTitleDisplay}}</span>
                        <span class="material-icons" style="margin-left:auto">groups</span>
                     </a>
                  </li>

                  <li *ngIf="accessRightsService.isUserConnected" class="nav-item"
                     [ngClass]="{ 'active': admindevelopment.isActive }">
                     <a class="nav-link" routerLink="/admindevelopment" routerLinkActive
                        #admindevelopment="routerLinkActive" (click)="toggleMenu()">
                        <span class="menu-title">{{adminDevelopmentTitleDisplay}}</span>
                        <span class="material-icons" style="margin-left:auto">support</span>
                     </a>
                  </li>

                  <!-- 
                  <li *ngIf="accessRightsService.isUserConnected" class="nav-item" [ngClass]="{ 'active': adminmatchlist.isActive }">
                     <a class="nav-link" routerLink="/adminmatchlist" routerLinkActive
                        #adminmatchlist="routerLinkActive" (click)="toggleMenu()">
                        <span class="menu-title">{{adminMatchsListTitleDisplay}}</span>
                        <span class="material-icons" style="margin-left:auto">sports_volleyball</span>
                     </a>
                  </li> 
                  <li *ngIf="accessRightsService.isUserConnected" class="nav-item" [ngClass]="{ 'active': adminuserlist.isActive }">
                     <a class="nav-link" routerLink="/adminuserlist" routerLinkActive #adminuserlist="routerLinkActive"
                        (click)="toggleMenu()">
                        <span class="menu-title">{{adminUsersListTitleDisplay}}</span>
                        <span class="material-icons" style="margin-left:auto">person</span>
                     </a>
                  </li>
                  <li *ngIf="accessRightsService.isUserConnected" class="nav-item"
                     [ngClass]="{ 'active': adminbuildingtraininglist.isActive }">
                     <a class="nav-link" routerLink="/adminbuildingtraininglist" routerLinkActive
                        #adminbuildingtraininglist="routerLinkActive" (click)="toggleMenu()">
                        <span class="menu-title">{{adminTrainingBuildingsListTitleDisplay}}</span>
                        <span class="material-icons" style="margin-left:auto">apartment</span>
                     </a>
                  </li>
                  -->

               </ul>
            </div>
         </li>

         <li *ngIf="!accessRightsService.isUserConnected" class="nav-item" [ngClass]="{ 'active': login.isActive }">
            <a class="nav-link" routerLink="/login" routerLinkActive #login="routerLinkActive" (click)="toggleMenu()">
               <span class="menu-title">{{loginTitleDisplay}}</span>
               <span class="material-icons" style="margin-left:auto">account_circle</span>
            </a>
         </li>
      </ul>
   </div>
</nav>