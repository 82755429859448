@Reflect.metadata('changePassword', 'changePassword')
export class changePassword
{
    username: string;
    newPassword: string;
    accountSecurityCode: string;

    constructor(username: string, newPassword: string, accountSecurityCode: string) {
        this.username = username;
        this.newPassword = newPassword;
        this.accountSecurityCode = accountSecurityCode;
      }
}