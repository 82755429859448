import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry, throwError } from 'rxjs';

@Injectable()
export class TodoService {

   content : string = "";

   constructor(private httpClient: HttpClient) {
   }

   public getAllTodos() {
      return this.httpClient.get('./assets/todo.txt', { responseType: 'text' })
         .pipe(retry(1), catchError(this.handleError));
   }

   // Error handling 
   handleError(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
      } else {
         // Get server-side error
               if (error.status == 401)
        errorMessage = "Vous n'êtes pas autorisé à effectuer cette opération.";
      else
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(() => errorMessage);
   }
}
