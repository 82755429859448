<div class="mat-card-popup">
   <mat-card class="mat-card-color">
      <mat-card-header>
         <mat-card-title><img class="mat-card-icon" src="../../../sites/{{site()}}/images/account/register.png"
               alt="Register"> {{ 'RegistrationTitle' | translate }}</mat-card-title>
         <mat-card-subtitle>{{ 'AccountRegistrationDescription' | translate }}</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content class="mat-card-content-padding">
         <form [formGroup]="form" autocomplete="dontAutoComplete">
            <div *ngIf="!isRegistered">
               <div class="form-group text-center">
                  <input type="text" autocomplete="noac" formControlName="lastName" class="form-control"
                     placeholder="{{ 'LastName' | translate }}"
                     [ngClass]="{ 'is-invalid': submitted && form.controls.lastName.errors }" />
                  <div *ngIf="submitted && form.controls.lastName.errors" class="invalid-feedback">
                     <div *ngIf="form.controls.lastName.errors.required">{{ 'LastNameIsRequired' | translate }}</div>
                  </div>
               </div>

               <div class="form-group text-center">
                  <input type="text" autocomplete="noac" formControlName="firstName"
                     class="form-control" placeholder="{{ 'FirstName' | translate }}"
                     [ngClass]="{ 'is-invalid': submitted && form.controls.firstName.errors }" />
                  <div *ngIf="submitted && form.controls.firstName.errors" class="invalid-feedback">
                     <div *ngIf="form.controls.firstName.errors.required">{{ 'FirstNameIsRequired' | translate }}</div>
                  </div>
               </div>

               <div class="form-group text-center">
                  <input type="email" autocomplete="email" formControlName="email" class="form-control"
                     placeholder="{{ 'Email' | translate }}"
                     [ngClass]="{ 'is-invalid': submitted && form.controls.email.errors }" />
                  <div *ngIf="submitted && form.controls.email.errors" class="invalid-feedback">
                     <div *ngIf="form.controls.email.errors.required">{{ 'EmailIsRequired' | translate }}</div>
                  </div>
               </div>

               <div class="form-group text-center">
                  <input [type]="hidePassword ? 'password' : 'text'" maxLength="50" autocomplete="new-password"
                     formControlName="password" class="form-control"
                     placeholder="{{ 'Password' | translate }}"
                     [ngClass]="{ 'is-invalid': submitted && form.controls.password.errors }"
                     style="float:left;width:calc(100% - 30px)" />&nbsp;
                  <mat-icon matSuffix style="margin-top:10px;float:right" (click)="hidePassword = !hidePassword">
                     {{hidePassword ?
                     'visibility_off' : 'visibility'}}</mat-icon>

                  <app-passwordStrength class="passwordStrength" [passwordToCheck]="getPassword()"
                     [barLabel]="barLabel"></app-passwordStrength>

                  <div *ngIf="form.controls.password.errors" class="invalid-feedback">
                     <div *ngIf="form.controls.password.errors.required">{{ 'PasswordIsRequired' | translate }}</div>
                     <div *ngIf="form.controls.password.errors.minlength">{{ 'PasswordCheckRule' | translate }}</div>
                     <div *ngIf="form.controls.password.errors.strengthPassword">{{ 'PasswordStrengthIsTooLow' |
                        translate }}</div>
                  </div>

               </div>

               <div class="form-group text-center">
                  <input [type]="hideConfirmationPassword ? 'password' : 'text'" maxLength="50"
                     autocomplete="new-password" formControlName="confirmPassword" class="form-control"
                     placeholder="{{ 'ConfirmPassword' | translate }}"
                     [ngClass]="{ 'is-invalid': submitted && form.controls.confirmPassword.errors }"
                     style="float:left;width:calc(100% - 30px)" />&nbsp;
                  <mat-icon matSuffix style="margin-top:10px;float:right"
                     (click)="hideConfirmationPassword = !hideConfirmationPassword">{{hideConfirmationPassword ?
                     'visibility_off' : 'visibility'}}</mat-icon>

                  <app-passwordStrength class="passwordStrength" [passwordToCheck]="getConfirmationPassword()"
                     [barLabel]="barLabel"></app-passwordStrength>

                  <div *ngIf="submitted && form.controls.confirmPassword.errors" class="invalid-feedback">
                     <div *ngIf="form.controls.confirmPassword.errors.required">{{ 'ConfirmPasswordIsRequired' |
                        translate }}</div>
                     <div *ngIf="form.controls.confirmPassword.errors.minlength">{{ 'ConfirmPasswordCheckRule' |
                        translate }}</div>
                     <div *ngIf="form.controls.confirmPassword.errors.confirmedValidator">{{
                        'ConfirmPasswordMustBeMatch' | translate }}</div>
                  </div>
               </div>
            </div>

            <app-alertShow></app-alertShow>

            <div *ngIf="isRegistered">
               {{ 'ClickHereToLogin1' | translate }} <a routerLink="/login" class="text-primary">{{ 'LoginSignInCommand'
                  | translate }}</a> {{ 'ClickHereToLogin2' | translate }}
            </div>

            <div *ngIf="!isRegistered">
               <div class="text-center font-weight-light">
                  <app-button icon="power_settings_new" [class]="'spanButtonSeparator'" [text]="'AccountRegisterCommand' | translate" [isBackgroundOpaque]="true" (onClick)="onRegisterCommand()"></app-button>
               </div>

               <div style="height:2%"><br /></div>

               <div class="text-center font-weight-light">
                  {{ 'AlreadyHaveAccount' | translate }} <a routerLink="/login" class="auth-link text-white">{{
                     'LoginConnectionTitle' | translate }}</a>
               </div>
            </div>
         </form>
      </mat-card-content>
   </mat-card>
</div>