@Reflect.metadata('auth', 'auth')
export class auth
{
    username: string;
    password: string;

    constructor(username: string, password: string) {
        this.username = username;
        this.password = password;
      }
}