<nav>
   <!-- ROW 1 -->
   <div class="navbarTop">
      <!-- LOGO -->
      <div [class]="subjectService.IsGlobalCacheInLoading ? 'logoVABNavbar logoVABBase64 at-item animate-flicker' : 'logoVABNavbar logoVABBase64'" (click)="reloadWebsite()">
      </div>

      <div class="navbarVabourlettisLogo">
         <!-- TITLE -->
         <div class="navbarVabourlettisNormalFont contentTitle">
            <span class="navbarVabourlettisBigFont">V</span>olley&nbsp;
            <span class="navbarVabourlettisBigFont">A</span>nderlues&nbsp;
            <span class="navbarVabourlettisBigFont">B</span>ourlettis&nbsp;
         </div>
         <div class="navbarSeasonSeparator"></div>
         <!-- SEASON -->
         <div class="navbarSeason">Saison {{getCurrentChampionshipName()}}
            <!-- WIFI ON ICON -->
            <span (click)="refreshWebsite()" *ngIf="isNetworkStatusOnline" class="navbarMenuAlignment wifi"><span [class]="getWorkerServiceClass()">wifi</span>
            </span>
            <!-- WIFI OFF ICON -->
            <span (click)="refreshWebsite()" *ngIf="!isNetworkStatusOnline" class="navbarMenuAlignment wifi"><span [class]="getWorkerServiceClass()">wifi_off</span>
            </span>
            <!-- MENU -->
            <span *ngIf="isInformationEditable() && !isTurnAdminButtonsOff"
               class="material-icons editButton animatedButton"
               [routerLink]="['/adminchampionshiplist']">more_horiz</span>
         </div>
      </div>

      <div class="navbarVabourlettis" *ngIf="accessRightsService.isUserConnected">
         <div class="navbarCommand">
            <!-- PROFIL -->
            <span class="navbarMenuAlignment">
               <app-buttonmenu [isBackgroundOpaque]="true" icon="person" [routerLink]="['/profile']"
                  [queryParams]="{id: '-1'}"></app-buttonmenu>
            </span>
            <!-- LOG OUT -->
            <span class="navbarMenuAlignment">
               <app-buttonmenu [isBackgroundOpaque]="true" icon="power_settings_new" routerLink="/loginout"
                  (click)="logout()"></app-buttonmenu>
            </span>
         </div>
         <!-- USER CONNECTED -->
         <div class="navbarUserTitle contentUserTitle">
            {{userInitialDisplay}}
         </div>
      </div>

      <!-- LOG IN -->
      <div *ngIf="!accessRightsService.isUserConnected">
         <div class="navbarLinkLogOut">
            <div class="marginLeftAuto">
               <app-buttonmenu [isBackgroundOpaque]="true" icon="account_circle" routerLink="/loginout"
                  (click)="login()"></app-buttonmenu>
            </div>
         </div>
      </div>
   </div>

   <!--  ROW 2 COMPUTER -->
   <div [class]="backColorEnv()" *ngIf="!isDisplayOnAMobile">
      <div>
         <span *ngIf="subjectService.IsGlobalCacheInLoading" class="cache navbarMenuAlignment">
            <img class="cacheImage rotate" src="../../../assets/images/system/spin.png">
         </span>
         <button *ngIf="!subjectService.IsGlobalCacheInLoading" class="navbarToggler" type="button" data-toggle="minimize"
            (click)="toggleSidebar()">
            <span class="material-icons-bigger">toggle_off</span>
         </button>
      </div>

      <app-buttonmenu icon="home" routerLink="/home"></app-buttonmenu>
      <app-buttonmenu icon="campaign" routerLink="/activity"></app-buttonmenu>
      <app-buttonmenu icon="groups" routerLink="/team"></app-buttonmenu>
      <app-buttonmenu icon="date_range" routerLink="/diary"></app-buttonmenu>
      <app-buttonmenu icon="emoji_events" routerLink="/ranking"></app-buttonmenu>
      <app-buttonmenu icon="event_note" routerLink="/comiteeMeeting" [visibility]="accessRightsService.isUserConnectedInComitee()"></app-buttonmenu>

      <app-buttonmenu icon="phone" routerLink="/contactus" [visibility]="!accessRightsService.isUserConnected"></app-buttonmenu>
      <app-buttonmenu icon="directions_run" routerLink="/trainingInfo"
         [visibility]="!accessRightsService.isUserConnected"></app-buttonmenu>
      <app-buttonmenu icon="festival" routerLink="/club" [visibility]="!accessRightsService.isUserConnected"></app-buttonmenu>

      <app-buttonmenu icon="thumbs_up_down" routerLink="/trainingPlayer"
         [visibility]="accessRightsService.isUserConnected && accessRightsService.isUserConnectedAssignedToATeam()"></app-buttonmenu>
      <app-buttonmenu icon="people" routerLink="/member" [visibility]="accessRightsService.isUserConnected"></app-buttonmenu>
      <app-buttonmenu icon="../../../sites/{{site()}}/images/content/facebook.png" href="https://www.facebook.com/vabourlettis"
         alt="VAB facebook"></app-buttonmenu>
      <app-buttonmenu icon="../../../sites/{{site()}}/images/content/facebookGroup.png" href="https://www.facebook.com/groups/2385128264896160"
      [visibility]="accessRightsService.isUserConnected" alt="VAB facebook group"></app-buttonmenu>

      <app-buttonmenu icon="volunteer_activism" routerLink="/sponsor"
         [visibility]="!accessRightsService.isUserConnected"></app-buttonmenu>
      <app-buttonmenu icon="../../../sites/{{site()}}/images/content/Fvwb.jpg" href="https://www.portailfvwb.be/" alt="Fvwb"
         [visibility]="accessRightsService.isUserConnected"></app-buttonmenu>
      <app-buttonmenu icon="../../../sites/{{site()}}/images/content/Achvb.jpg" href="https://www.achvb.be/" alt="Achvb"
         [visibility]="accessRightsService.isUserConnected"></app-buttonmenu>

      <app-buttonmenu [isFlicked]="isSynchronisationRunning" icon="change_circle" routerLink="/fvwbSynchronize" (click)="fvwbSynchronize(1)"
         [visibility]="accessRightsService.isUserConnectedAdministratorOrSupervisor()"></app-buttonmenu>
      <app-buttonmenu icon="cleaning_services" href="onclick" (onClick)="setReset()"
         [visibility]="accessRightsService.isUserConnectedAdministratorOrSupervisor()"></app-buttonmenu>
      <app-buttonmenu [icon]="isTurnAdminButtonsOff ? 'edit' : 'edit_off'" href="onclick" (onClick)="turnOnOffAdminButtons()"
         [visibility]="accessRightsService.isUserConnectedAdministratorOrSupervisor()"></app-buttonmenu>

      <div class="navbarLoginTitle" *ngIf="!accessRightsService.isUserConnected">
         <div class="welcome">
            <span class="material-icons">privacy_tip</span> Veuillez vous connecter si vous êtes membre.
         </div>
      </div>
   </div>


   <!--  ROW 2 MOBILE -->
   <div [class]="backColorEnv()" *ngIf="isDisplayOnAMobile">
      <app-buttonmenu icon="home" routerLink="/home"></app-buttonmenu>
      <app-buttonmenu icon="campaign" routerLink="/activity"></app-buttonmenu>
      <app-buttonmenu icon="groups" routerLink="/team"></app-buttonmenu>
      <app-buttonmenu icon="date_range" routerLink="/diary"></app-buttonmenu>
      <app-buttonmenu icon="emoji_events" routerLink="/ranking"></app-buttonmenu>
      <app-buttonmenu icon="event_note" routerLink="/comiteeMeeting" [visibility]="accessRightsService.isUserConnectedInComitee()"></app-buttonmenu>
      <app-buttonmenu icon="phone" routerLink="/contactus" [visibility]="!accessRightsService.isUserConnected"></app-buttonmenu>
      <app-buttonmenu icon="directions_run" routerLink="/trainingInfo"
         [visibility]="!accessRightsService.isUserConnected"></app-buttonmenu>
      <app-buttonmenu icon="festival" routerLink="/club" [visibility]="!accessRightsService.isUserConnected"></app-buttonmenu>

      <app-buttonmenu icon="thumbs_up_down" routerLink="/trainingPlayer"
         [visibility]="accessRightsService.isUserConnected && accessRightsService.isUserConnectedAssignedToATeam()"></app-buttonmenu>
      <app-buttonmenu icon="people" routerLink="/member" [visibility]="accessRightsService.isUserConnected"></app-buttonmenu>
   </div>
   <!-- ROW 3 MOBILE -->
   <div [class]="backColorEnv()" *ngIf="isDisplayOnAMobile">
      <app-buttonmenu icon="volunteer_activism" routerLink="/sponsor"
         [visibility]="!accessRightsService.isUserConnected"></app-buttonmenu>
      <app-buttonmenu icon="logoFacebookBase64" href="https://www.facebook.com/vabourlettis"
         alt="VAB facebook"></app-buttonmenu>
      <app-buttonmenu icon="logoFacebookGroupBase64" href="https://www.facebook.com/groups/2385128264896160"
         [visibility]="accessRightsService.isUserConnected" alt="VAB facebook group"></app-buttonmenu>
      <app-buttonmenu icon="logoFvwbBase64" href="https://www.portailfvwb.be/" alt="Fvwb"
         [visibility]="accessRightsService.isUserConnected"></app-buttonmenu>
      <app-buttonmenu icon="logoAchvbBase64" href="https://www.achvb.be/" alt="Achvb"
         [visibility]="accessRightsService.isUserConnected"></app-buttonmenu>

      <app-buttonmenu [isFlicked]="isSynchronisationRunning" icon="change_circle" routerLink="/fvwbSynchronize" (click)="fvwbSynchronize(1)"
         [visibility]="accessRightsService.isUserConnectedAdministratorOrSupervisor()"></app-buttonmenu>
      <app-buttonmenu icon="cleaning_services" href="onclick" (onClick)="setReset()"
         [visibility]="accessRightsService.isUserConnectedAdministratorOrSupervisor()"></app-buttonmenu>
      <app-buttonmenu [icon]="isTurnAdminButtonsOff ? 'edit' : 'edit_off'" href="onclick" (onClick)="turnOnOffAdminButtons()"
         [visibility]="accessRightsService.isUserConnectedAdministratorOrSupervisor()"></app-buttonmenu>

      <div class="navbarLogAlignment">
         <div class="spinMargin navbarMenuAlignment">
            <span *ngIf="subjectService.IsGlobalCacheInLoading" class="cache navbarMenuAlignment">
               <img class="cacheImage rotate" src="../../../assets/images/system/spin.png">
            </span>
            <div *ngIf="!subjectService.IsGlobalCacheInLoading" class="navbarLink animatedButton">
               <a class="nav-link"><span class="material-icons-bigger" id="toggleMenu"
                     (click)="toggleOffcanvas()">menu</span></a>
            </div>
         </div>
      </div>
   </div>

   <!-- ROW 4 MOBILE -->
   <div [class]="backColorEnv()" *ngIf="isDisplayOnAMobile && !accessRightsService.isUserConnected">
      <div class="navbarLoginTitle">
         <div class="welcome">
            <span class="material-icons">privacy_tip</span> Veuillez vous connecter si vous êtes membre.
         </div>
      </div>
   </div>
</nav>